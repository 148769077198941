import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import styles from "./individual-article.module.css";
import LoadingIcon from "../../../components/loading";
import NotFound from "../../not-found";

export default function IndividualArticle(props) {
	const [article, setArticle] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const { articleId } = useParams();

	useEffect(() => {
		axios
			.get(`/BlogPost/${articleId}`)
			.then(resp => {
				const article = { ...resp.data };

				if (!article.published) {
					setIsLoading(false);
					return;
				}

				const postedDate = new Date(article.postedOn);
				let options = { month: "long", day: "numeric", year: "numeric" };
				article.postedOn = postedDate.toLocaleDateString("en-US", options);
				const modifiedOptions = { ...options, hour: "numeric", minute: "numeric", second: "numeric" };
				const modifiedDate = new Date(article.lastModified);
				article.lastModified = modifiedDate.toLocaleDateString("en-US", modifiedOptions);
				setArticle(article);
				setIsLoading(false);
			})
			.catch(err => {
				toast.error(err.message);
				setIsLoading(false);
			});
	}, [articleId]);

	if (isLoading) {
		return (
			<section className={styles.loadingContainer}>
				Loading Article...
				<LoadingIcon />
			</section>
		);
	}

	if (!article) {
		return <NotFound />;
	}

	return (
		<article className={styles.articleContainer}>
			<h1>{article.title}</h1>
			<p>Article by {article.author}</p>
			<p>
				Posted on {article.postedOn} | Last Modified: {article.lastModified}
			</p>

			<div id="richTextArticle" dangerouslySetInnerHTML={{ __html: article.body }}></div>
		</article>
	);
}
