import { useContext } from "react";
import LoginForm from "../../../components/account/login-form";
import LogoutForm from "../../../components/account/logout-form";
import AuthContext from "../../../context/auth-context";

export default function Account() {

	const authContext = useContext(AuthContext);

	let content = <LoginForm></LoginForm>

	if (authContext.isLoggedIn()) {
		content = <LogoutForm></LogoutForm>
	}

	return content;

}
