import { useState } from "react";
import formBuilder from "../../../components/Forms/formBuilder";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "../locations/add-location.module.css";
import LoadingIcon from "../../../components/loading";

export default function AddProvider() {
	const [providerForm, setProviderForm] = useState({
		firstName: formBuilder.configInput("input", "text", "First Name", null, { required: true, minLength: 2 }),
		lastName: formBuilder.configInput("input", "text", "Last Name", null, { required: true }),
		roomUrl: formBuilder.configInput("input", "text", "Room Id", null, {}),
		linkTo: formBuilder.configInput("input", "text", "Url", null, { required: true }, ""),
	});
	const navigate = useNavigate();
	const [error, setError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const handleInputChange = (evt, id) => {
		const updatedForm = { ...providerForm };
		const updatedElement = { ...updatedForm[id] };
		updatedElement.value = evt.target.value;
		updatedForm[id] = updatedElement;
		formBuilder.checkValidity(updatedElement);
		setProviderForm(updatedForm);
	};

	const handleFormSubmit = evt => {
		evt.preventDefault();

		let hasError = error ? false : false;
		for (let elt in providerForm) {
			formBuilder.checkValidity(providerForm[elt]);
			if (!providerForm[elt].valid) {
				hasError = true;
			}
		}

		if (hasError) {
			toast.error("you must properly fill out the form");
			setError(true);
			return;
		}

		const data = {
			firstName: providerForm.firstName.value,
			lastName: providerForm.lastName.value,
			roomUrl: providerForm.roomUrl.value,
			toLink: providerForm.linkTo.value,
		};
		setIsLoading(true);
		axios
			.post("/Provider", data)
			.then(resp => {
				if (resp.status === 201) {
					toast.success(`${data.firstName} ${data.lastName} was added`);
					navigate("/admin/providers");
				} else {
					toast.error("There was an error with your request");
					setIsLoading(false);
				}
			})
			.catch(err => {
				setIsLoading(false);
				toast.error(err.message);
			});
	};

	const form = formBuilder.buildForm(providerForm, handleInputChange);

	const providerFormDisplay = !isLoading ? (
		<form className={styles.locationForm}>
			{form}
			<div className={styles.btnContainer}>
				<Link to={"/admin/providers"}>Cancel</Link>
				<button onClick={handleFormSubmit}>Add Provider</button>
			</div>
		</form>
	) : (
		<LoadingIcon message="saving" />
	);

	return (
		<>
			<h1>Add a Provider</h1>
			{providerFormDisplay}
		</>
	);
}
