import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faMagnifyingGlass
} from "@fortawesome/free-solid-svg-icons";
import faqData from "../../../lib/faq-data";
import FaqItem from "../../../components/faq-item";
import styles from "./faq.module.css";


function Faqs() {
	const [searchTerm, setSearchTerm] = useState("");
	const inputRef = useRef();
	const [displayTopics, setDisplayTopics] = useState([]);

	let renderData = <p>Sorry, no related search results found!</p>;

	useEffect(() => {
		setDisplayTopics(faqData[0].faqs);
	}, []);

	useEffect(() => {
		const timerId = setTimeout(() => {
			if (inputRef.current.value === searchTerm) {
				const searchFor = searchTerm.toLowerCase();
				const insurance = faqData[0].faqs.filter(
					elt => searchTerm === "" || elt.title.toLowerCase().includes(searchFor) || elt.content.toLowerCase().includes(searchFor)
				);

				setDisplayTopics(insurance);
			}
		}, 500);

		return () => clearTimeout(timerId);
	}, [searchTerm]);

	const handleSearch = evt => {
		setSearchTerm(evt.target.value);
	};

	const handleSearchInput = evt => {
		evt.preventDefault();
	};

	if (displayTopics.length > 0) {
		renderData = (
			<ul>
				{displayTopics.map(topic => (
					<FaqItem key={topic.id} title={topic.title} content={topic.content} id={topic.id} />
				))}
			</ul>
		);
	}

	return (
		<section>
			<h1>FAQs</h1>
			<div className={styles.faqContainer}>
				<div className={styles.faqSearch}>
					<h2>Quick Search</h2>
					<form onSubmit={handleSearchInput}>
						<input
							type="search"
							aria-label="Search for a topic"
							name="Search"
							placeholder="Type a word or phrase"
							ref={inputRef}
							onChange={handleSearch}></input>
						<button aria-label="Press to search for a topic">
							<FontAwesomeIcon icon={faMagnifyingGlass} size="2xl" color="#033F63" />
						</button>
					</form>
				</div>
				{/* <div className={styles.faqButtons}>{buttons}</div> */}
				<div className={styles.faqItemsContainer}>{renderData}</div>
			</div>
		</section>
	);
}

export default Faqs;
