import { useEffect, useRef, useState, useCallback } from "react";
import { Marker, GoogleMap, InfoWindow } from "@react-google-maps/api";
import styles from "./map.module.css";

const defaultCenter = { lat: 37.0902, lng: -95.7129 };

const mapOptions = { fullscreenControl: false, mapTypeControl: false };

function Map(props) {
	const ref = useRef();
	const [zoomLevelToSet, setZoomLevel] = useState(null);
	const [map, setMap] = useState(null);
	const [selectedMarker, setSelectedMarker] = useState("");

	const markerIcon = {
		url: "../images/Ellipse2.png",
		scaledSize: new window.google.maps.Size(30, 30),
	};

	useEffect(() => {
		if (props.selectLocation && map) {
			const bounds = new window.google.maps.LatLngBounds();
			const place = { lat: props.selectLocation.latitude, lng: props.selectLocation.longitude };
			bounds.extend(new window.google.maps.LatLng(place));
			map.fitBounds(bounds);
			setZoomLevel(15);
		} else if (props.locations && map) {
			const bounds = new window.google.maps.LatLngBounds();

			props.locations.forEach(location => {
				const place = { lat: location.latitude, lng: location.longitude };
				bounds.extend(new window.google.maps.LatLng(place));
			});

			map.fitBounds(bounds);
			setZoomLevel(null);
		}
	}, [props.locations, map, props.selectLocation]);

	const handleOnLoad = useCallback(function callback(mapInstance) {
		setMap(mapInstance);
	}, []);

	const handleTilesLoaded = () => {
		if (map && zoomLevelToSet !== null) {
			map.setZoom(zoomLevelToSet);
			setZoomLevel(null);
		}
	};

	const handleMarkerClick = marker => {
		setSelectedMarker(marker);
	};

	const handleInfoWindowClose = () => {
		setSelectedMarker("");
	};

	return (
		<GoogleMap
			ref={ref}
			id="map"
			zoom={8}
			center={defaultCenter}
			onLoad={handleOnLoad}
			onTilesLoaded={handleTilesLoaded}
			options={mapOptions}>
			{props.locations.map((location, idx) => {
				const place = { lat: location.latitude, lng: location.longitude };

				const address = location.suite ? `${location.street}, ${location.suite}` : location.street;

				return (
					<Marker
						key={location.locationId}
						position={place}
						clickable={true}
						icon={markerIcon}
						label={`${idx + 1}`}
						onClick={() => handleMarkerClick(location)}>
						{selectedMarker === location && (
							<InfoWindow onCloseClick={handleInfoWindowClose}>
								<div>
									<p className={styles.windowLocation}>{location.name}</p>
									<p className={styles.address}>{address}</p>
									<p className={styles.address}>
										{location.city}, {location.stateProvinceAbbreviation}, {location.postalCode}
									</p>
									<a className={styles.address} href={`tel:+1${location.phone}`}>
										{location.phone}
									</a>
								</div>
							</InfoWindow>
						)}
					</Marker>
				);
			})}
		</GoogleMap>
	);
}

export default Map;
