import { useState, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import styles from "./contact.module.css";
import formBuilder from "../../../components/Forms/formBuilder";
import { toast } from "react-toastify";
import axios from "axios";
import LoadingIcon from "../../../components/loading";
import ReCAPTCHA from 'react-google-recaptcha';

function Contact() {
	const [subscribeBox, setSubscribeBox] = useState(true);
	const [formSent, setFormSent] = useState(false);
	const [contactForm, setContactForm] = useState({
		name: formBuilder.configInput("input", "text", "Name", null, { required: true, minLength: 2 }),
		email: formBuilder.configInput("input", "email", "Email", null, { required: true }),
		phone: formBuilder.configInput("input", "phone", "Phone", null, {}),
		message: formBuilder.configInput("textarea", "", "Message", null, { required: true }),
	});
	const [error, setError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const captchaRef = useRef(null);

	const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

	const handleInputChange = (evt, id) => {
		const updatedForm = { ...contactForm };
		const updatedElement = { ...updatedForm[id] };
		updatedElement.value = evt.target.value;
		updatedForm[id] = updatedElement;
		formBuilder.checkValidity(updatedElement);
		setContactForm(updatedForm);
	};

	const handleCheckBoxChange = useCallback(() => {
		setSubscribeBox(sub => !sub);
	}, []);

	const handleSubmit = async event => {
		
		event.preventDefault();

		let hasError = error ? false : false;
		for (let elt in contactForm) {
			formBuilder.checkValidity(contactForm[elt]);
			if (!contactForm[elt].valid) {
				hasError = true;
			}
		}

		if (hasError) {
			toast.error("You must properly fill out the form.");
			setError(true);
			return;
		}

		let token = captchaRef.current.getValue();
		captchaRef.current.reset();

		if (token) {
			setIsLoading(true);
			const data = {
				name: contactForm.name.value,
				email: contactForm.email.value,
				phone: contactForm.phone.value,
				message: contactForm.message.value,
				permissionChecked: subscribeBox,
				captchaToken: token,
			};

			axios
				.post("/Contact", data)
				.then(resp => {
					if (resp.status === 200) {
						setFormSent(true);
					} else {
						toast.error("There was an error with your request, please try again shortly.");
					}
					setIsLoading(false);
				})
				.catch(err => {
					setIsLoading(false);
					toast.error(err.message);
				});
		}
		else {
			toast.error('You must fill out the captcha.')
		}


		
	};

	const contact = formBuilder.buildForm(contactForm, handleInputChange);

	let formMessage;
	if (isLoading) {
		formMessage = <LoadingIcon message="sending" />;
	} else if (formSent) {
		formMessage = (
			<div className={styles.sentMessageContainer}>
				<h2>Message Sent!</h2>
				<img src="/images/AcornThumbsUp.svg" alt=""></img>
				<p>An Anew staff member will get in touch with you within 2 business days.</p>
				<Link to={"/"} className={styles.homeLink}>
					Home
				</Link>
			</div>
		);
	} else {
		formMessage = (
			<>
				<p>If you or a loved one is experiencing an emergency, please call 911.</p>
				<p>
				If you prefer to contact us via email, please submit the form below, and an Anew staff member will get in touch with you within 2 business days. If you would like to contact us by phone, please feel free to call <a href="tel:+18665342639">866-534-ANEW</a>.
				</p>
				<div className={styles.formContainer}>
					<form onSubmit={handleSubmit} className={styles.contactForm}>
						{contact}
						<div>
							<label className={styles.container}>
								By checking this box and submitting your information, you are granting us permission to
								email you. You may unsubscribe at any time.
								<input type="checkbox" name="subscribe" defaultChecked onClick={handleCheckBoxChange} />
								<span className={styles.checkmark}></span>
							</label>
						</div>

						<div>
						<ReCAPTCHA
							className="recaptcha"
							sitekey={SITE_KEY}
							ref={captchaRef}
							/>
						</div>

						<button className="primary-cta">Send</button>
					</form>
				</div>
			</>
		);
	}

	return (
		<>
		<section className={styles.instantAccessWrapper}>
			<h1>Instant Access</h1>
			<p>Anew offers Instant Access! If you are interested in Beginning Anew, or you would like to make a referral, or you just have a few questions, click below to be connected to a member of the Intake and Access team.</p>
			<div className="text-center">
				<a href="https://anewbh.doxy.me/intake1" className={styles.instantAccess} target="_blank" rel="noreferrer">Instant Access</a>
			</div>
		</section>
		<section className={styles.contactPage}>
			<h2>Contact</h2>
			{formMessage}
		</section>
		</>
	);
}

export default Contact;
