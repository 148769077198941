import { useState } from "react";
import formBuilder from "../Forms/formBuilder";
import axios from "axios";
import { toast } from "react-toastify";
import styles from "./add-state.module.css";

export default function AddState(props) {
	const [stateForm, setStateForm] = useState({
		name: formBuilder.configInput("input", "text", "Name", null, { required: true }),
		abbreviation: formBuilder.configInput("input", "text", "Abbreviation", null, { reqLength: 2 }),
	});
	const [error, setError] = useState(false);

	const handleInputChange = (evt, id) => {
		const updatedForm = { ...stateForm };
		const updatedElement = { ...updatedForm[id] };
		updatedElement.value = evt.target.value;
		updatedForm[id] = updatedElement;
		formBuilder.checkValidity(updatedElement);
		setStateForm(updatedForm);
	};

	const handleFormSubmit = evt => {
		evt.preventDefault();

		let hasError = error ? false : false;
		for (let elt in stateForm) {
			formBuilder.checkValidity(stateForm[elt]);
			if (!stateForm[elt].valid) {
				hasError = true;
			}
		}

		if (hasError) {
			toast.error("you must properly fill out the form");
			setError(true);
			return;
		}

		const data = {
			name: stateForm.name.value,
			abbreviation: stateForm.abbreviation.value,
		};

		axios
			.post("StateProvince", data)
			.then(resp => {
				if (resp.status === 201) {
					toast.success("State Added");
					props.success(resp.data);
				} else {
					toast.error("There was an error with your request");
				}
			})
			.catch(err => toast.error(err));
	};

	const form = formBuilder.buildForm(stateForm, handleInputChange);

	return (
		<div className={styles.stateModal}>
			<div>
				<p>Add a state</p>
				<button onClick={props.close}>X</button>
			</div>
			<form>
				{form}
				<div className={styles.buttonContainer}>
					<button onClick={props.close}>Cancel</button>
					<button onClick={handleFormSubmit}>Add State</button>
				</div>
			</form>
		</div>
	);
}
