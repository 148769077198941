import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import howHelp from "../../../lib/how-help-data";
import styles from "./individual-help.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { CSSTransition } from "react-transition-group";

export default function IndividualHelp() {
	const [toggleDropdown, setToggleDropdown] = useState(false);
	const [mainTopic, setMainTopic] = useState(null);
	const [selectedItem, setSelectedItem] = useState(null);
	const [isVideoPlaying, setIsVideoPlaying] = useState(false);
	const [isEnter, setIsEnter] = useState(false);
	const [muted, setMuted] = useState(false);
	const helpVideoRef = useRef(null);
	const navigate = useNavigate();

	const { shortUrl } = useParams();

	useEffect(() => {
		const findHelp = url => {
			const topic = howHelp.find(topic => topic.shortUrl === shortUrl);
			setSelectedItem(topic.subTopic[0]);
			setMainTopic(topic);
		};
		findHelp(shortUrl);
	}, [shortUrl]);

	useEffect(() => {
		const timerId = setTimeout(() => {
			setIsEnter(true);
		}, 400);

		return () => {
			clearTimeout(timerId);
		};
	}, []);

	const handlePlayVideo = useCallback(() => {
		if (isVideoPlaying) {
			helpVideoRef.current.pause();
			setIsVideoPlaying(false);
		} else {
			helpVideoRef.current.play();
			setIsVideoPlaying(true);
		}
	}, [isVideoPlaying]);

	const handlePauseVideo = useCallback(() => {
		if (isVideoPlaying) {
			helpVideoRef.current.pause();
			setIsVideoPlaying(false);
		}
	}, [isVideoPlaying]);

	const handleTranscriptShow = useCallback((evt) => {
		const transcript = mainTopic.subTopic.find(topic => topic.id === parseInt(evt.target.id));
		handlePauseVideo();
		setSelectedItem(transcript);
		setToggleDropdown(false);
	}, [handlePauseVideo, mainTopic?.subTopic]);

	const handleRestartVideo = useCallback(() => {
		const video = helpVideoRef.current;
		video.load();
		if (isVideoPlaying) {
			video.play();
		}
	}, [isVideoPlaying]);

	const handleMute = useCallback(() => {
		if (muted) {
			setMuted(false);
		} else {
			setMuted(true);
		}
	}, [muted]);

	const handlePageReturn = useCallback(() => {
		setIsEnter(false);
		setTimeout(() => {
			navigate("/how-we-can-help");
		}, 400);
	}, [navigate]);

	const handleShowDropdown = useCallback(() => {
		setToggleDropdown(!toggleDropdown);
	}, [setToggleDropdown, toggleDropdown]);


	if (!selectedItem) {
		return <div>Loading...</div>;
	}


	let toggleClass = toggleDropdown ? styles.toggleVisible : styles.toggleInvisible;

	let topicButtons = null;

	if (mainTopic.subTopic.length) {
		topicButtons = (
			<>
				<ul className={toggleClass}>
					<li onClick={handleShowDropdown}>
						<p className={styles.mobileButton}>
							{selectedItem.title}
							<FontAwesomeIcon icon={faChevronUp} />
						</p>
					</li>
					{mainTopic.subTopic.map(topic => {
						let imgStyle = { opacity: 0 };
						if (topic.id === selectedItem.id) {
							imgStyle = { opacity: 1 };
						}

						if (topic.title === "Medically Assisted Treatment (MAT)") {
							return (
								<li key={topic.id}>
									<img src="/images/leaf-icon.svg" alt="" style={imgStyle}></img>
									<button id={topic.id} onClick={handleTranscriptShow}>
										Medically Assisted <br></br>Treatment (MAT)
									</button>
								</li>
							);
						}
						return (
							<li key={topic.id}>
								<img src="/images/leaf-icon.svg" alt="" style={imgStyle}></img>
								<button id={topic.id} onClick={handleTranscriptShow}>
									{topic.title}
								</button>
							</li>
						);
					})}
				</ul>
			</>
		);
	}

	const playButton = !isVideoPlaying ? "fa-circle-play" : "fa-circle-pause";
	const soundButton = !muted ? "fa-volume-high" : "fa-volume-xmark";

	const dropDownSection = (
		<>
			<button className={styles.mobileButton} onClick={handleShowDropdown}>
				{selectedItem.title}
				<FontAwesomeIcon icon={faChevronDown} />
			</button>
			{topicButtons}
		</>
	);

	return (
		<section className={styles.container}>
			{toggleDropdown && <div className={styles.modal} onClick={handleShowDropdown}></div>}
			<h1>How We Can Help</h1>
			<CSSTransition
				in={isEnter}
				timeout={1000}
				classNames={{
					enter: "translate-enter",
					enterActive: "translate-enter-active",
					exit: "translate-exit",
					exitActive: "translate-exit-active",
				}}
				mountOnEnter
				unmountOnExit>
				<div className={styles.selectedHelpTopics}>
					<div className="col-12 row">
						<button aria-label="Return to 'How We Can Help' page" onClick={handlePageReturn}>
							<i className="fa-solid fa-chevron-left"></i>
						</button>
						<h2>{mainTopic.topic}</h2>
					</div>

					<div className={styles.transcriptContainer}>
						{dropDownSection}
						<div className={`${mainTopic.color}`}>
							<div
								className={styles.textContainer}
								tabIndex={0}
								role="region"
								aria-label="Scroll topic text">
								<p
									key={selectedItem.id}
									className="fade-in"
									dangerouslySetInnerHTML={{ __html: selectedItem.content }}></p>
							</div>
							<div className={styles.helpVideoControlsContainer}>
								<button aria-label="Play Video" onClick={handlePlayVideo}>
									<i className={`fa-regular ${playButton}`}></i>
								</button>
								<button aria-label="Mute Video" onClick={handleMute}>
									<i className={`fa-solid ${soundButton}`}></i>
								</button>
								<button aria-label="Restart Video" onClick={handleRestartVideo}>
									<i className="fa-solid fa-rotate-left"></i>
								</button>
							</div>
						</div>
					</div>
					{/* )} */}

					<div className={styles.helpVideoContainer}>
						<video
							key={selectedItem.videoSrc}
							ref={helpVideoRef}
							preload="metadata"
							muted={muted}
							className="fade-in">
							<source src={`${selectedItem.videoSrc}#t=0.001`}></source>
							<p>
								Your browser doesn't support HTML video. Here is a
								<a href={selectedItem.videoSrc}>link to the video</a> instead.
							</p>
						</video>
					</div>

					<div className={styles.helpVideoControlsMobileContainer}>
						<button aria-label="Play Video" onClick={handlePlayVideo}>
							<i className={`fa-regular ${playButton}`}></i>
						</button>
						<button aria-label="Mute Video" onClick={handleMute}>
							<i className={`fa-solid ${soundButton}`}></i>
						</button>
						<button aria-label="Restart Video" onClick={handleRestartVideo}>
							<i className="fa-solid fa-rotate-left"></i>
						</button>
					</div>

					<article>
						<p
							key={selectedItem.id}
							className="fade-in"
							dangerouslySetInnerHTML={{ __html: selectedItem.content }}></p>
					</article>
				</div>
			</CSSTransition>
		</section>
	);
}
