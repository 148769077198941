import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Routes, Route, useLocation } from "react-router-dom";
import styles from "./ContentContainer.module.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Homepage from "./pages/publicPages/home";
import ArticlesPage from "./pages/publicPages/articles/articles";
import IndividualArticle from "./pages/publicPages/articles/individual-article";
import Locations from "./pages/publicPages/locations/locations";
import HowHelp from "./pages/publicPages/how-help/how-help";
import IndividualHelp from "./pages/publicPages/how-help/individual-help";
import Faq from "./pages/publicPages/faq/faq";
import AnewFooter from "./components/footer";
import Contact from "./pages/publicPages/contact/contact";
import TeleHealth from "./pages/publicPages/telehealth/telehealth";
import Careers from "./pages/publicPages/careers/careers";
import NotFound from "./pages/not-found";
import OurTeamPage from "./pages/publicPages/our-team/ourTeamLoading";

import AdminHome from "./pages/adminPages/adminHome";
import ArticlesDashboard from "./pages/adminPages/articles/articlesDashboard";
import AddArticle from "./pages/adminPages/articles/add-article";
import EditArticle from "./pages/adminPages/articles/edit-article";
import LocationsDashboard from "./pages/adminPages/locations/locationsDashboard";
import AddLocation from "./pages/adminPages/locations/add-location";
import EditLocation from "./pages/adminPages/locations/edit-location";
import Account from "./pages/adminPages/account/account";
import ProvidersDashboard from "./pages/adminPages/providers/providers-dashboard";
import AddProvider from "./pages/adminPages/providers/add-provider";
import EditProvider from "./pages/adminPages/providers/edit-provider";
import RouteProtector from "./components/auth/route-protector";
import ForgotPassword from "./pages/adminPages/account/forgot-password";
import ResetPassword from "./pages/adminPages/account/reset-password";
import TestimonialsDashboard from "./pages/adminPages/testimonials/testimonialsDashboard";
import AddTestimonial from "./pages/adminPages/testimonials/add-testimonial";
import EditTestimonial from "./pages/adminPages/testimonials/edit-testimonial";

function ContentContainer() {
	const location = useLocation();

	return (
		<>
			<TransitionGroup component="div" className={styles.transitionWrapper}>
				<CSSTransition timeout={600} key={location.key} classNames={{ ...styles }}>
					<div className={styles.transitionSection}>
						<main className="container-width">
							<Routes location={location}>
								<Route path="/" element={<Homepage />} />
								<Route path="/how-we-can-help" element={<HowHelp />} />
								<Route path="/how-we-can-help/:shortUrl" element={<IndividualHelp />}></Route>
								<Route path="/our-team" element={<OurTeamPage />} />
								<Route path="/locations" element={<Locations />} />
								<Route path="/articles" element={<ArticlesPage />} />
								<Route path="/articles/:articleId" element={<IndividualArticle />} />
								<Route path="/contact" element={<Contact />} />
								<Route path="/faqs" element={<Faq />} />
								<Route path="/telehealth" element={<TeleHealth />} />
								<Route path="/careers" element={<Careers />} />
								<Route path="*" element={<NotFound />} />

								<Route path="/admin/account" element={<Account />} />
								<Route path="/admin/forgot-password" element={<ForgotPassword />} />
								<Route path="/admin/reset-password" element={<ResetPassword />} />
								<Route path="/admin" element={<RouteProtector><AdminHome /></RouteProtector>} />
								<Route path="/admin/dashboard" element={<RouteProtector><AdminHome /></RouteProtector>} />
								<Route path="/admin/articles" element={<RouteProtector><ArticlesDashboard /></RouteProtector>} />
								<Route path="/admin/articles/add" element={<RouteProtector><AddArticle /></RouteProtector>} />
								<Route path="/admin/articles/edit/:id" element={<RouteProtector><EditArticle /></RouteProtector>} />
								<Route path="/admin/locations" element={<RouteProtector><LocationsDashboard /></RouteProtector>} />
								<Route path="/admin/locations/add" element={<RouteProtector><AddLocation /></RouteProtector>} />
								<Route path="/admin/locations/edit/:id" element={<RouteProtector><EditLocation /></RouteProtector>} />
								<Route path="/admin/providers" element={<RouteProtector><ProvidersDashboard /></RouteProtector>} />
								<Route path="/admin/providers/add" element={<RouteProtector><AddProvider /></RouteProtector>} />
								<Route path="/admin/providers/edit/:id" element={<RouteProtector><EditProvider /></RouteProtector>} />
								<Route path="/admin/testimonials" element={<RouteProtector><TestimonialsDashboard /></RouteProtector>} />
								<Route path="/admin/testimonials/add" element={<RouteProtector><AddTestimonial /></RouteProtector>} />
								<Route path="/admin/testimonials/edit/:id" element={<RouteProtector><EditTestimonial /></RouteProtector>} />
								
							</Routes>
						</main>
						<AnewFooter />
					</div>
				</CSSTransition>
			</TransitionGroup>
			<ToastContainer />
		</>
	);
}

export default ContentContainer;
