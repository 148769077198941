import styles from "./articlesDashboard.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCirclePlus,
	faMagnifyingGlass,
	faEdit,
	faTrashCan,
	faArrowUpAZ,
	faArrowDownZA,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import LoadingIcon from "../../../components/loading";
import Backdrop from "../../../components/Modal/backdrop";
import DeleteModal from "../../../components/adminComponents/delete-modal";
import ServerError from "../../../components/serverError";

const tableHeadings = [
	{ topic: "Title", id: "title", class: "" },
	{ topic: "Author", id: "author", class: styles.hideLink },
	{ topic: "Published", id: "published", class: "" },
	{ topic: "Posted On", id: "postedOn", class: styles.desktopHide },
	{ topic: "Last Modified", id: "lastModified", class: styles.mobileHide },
];

export default function ArticlesDashboard() {
	const [blogs, setBlogs] = useState([]);
	const [displayBlogs, setDisplayBlogs] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const inputRef = useRef(null);
	const [filterTopic, setFilterTopic] = useState("lastModified");
	const [selectedTopic, setSelectedTopic] = useState("");
	const [loading, setLoading] = useState(true);
	const [displayModal, setDisplayModal] = useState(false);
	const [deleteItem, setDeleteItem] = useState(null);
	const [serverProblem, setServerProblem] = useState(false);

	useEffect(() => {
		axios
			.get("/BlogPost")
			.then(resp => {
				const blogs = [...resp.data];
				const topicsSort = blogs.sort((a, b) => a.lastModified.localeCompare(b.lastModified));
				topicsSort.forEach(blog => {
					if (!blog.postedOn) {
						blog.postedOn = "";
					}
				});
				setBlogs(topicsSort);
				setDisplayBlogs(topicsSort);
				setLoading(false);
			})
			.catch(err => {
				toast.error(err.message);
				setLoading(false);
				setServerProblem(true);
			});
	}, []);

	useEffect(() => {
		const timerId = setTimeout(() => {
			if (inputRef.current.value === searchTerm) {
				const displayBlogs = blogs.filter(
					blog => searchTerm === "" || blog.title.toLowerCase().includes(searchTerm.toLowerCase())
				);

				setDisplayBlogs(displayBlogs);
			}
		}, 500);

		return () => clearTimeout(timerId);
	}, [searchTerm, blogs]);

	useEffect(() => {
		let topicsSort;
		if (filterTopic === "published") {
			topicsSort = displayBlogs.sort((a, b) => a.published - b.published);
		} else {
			topicsSort = displayBlogs.sort((a, b) => a[filterTopic].localeCompare(b[filterTopic]));
		}
		setDisplayBlogs(topicsSort);
	}, [filterTopic, displayBlogs]);

	const handleInputChange = evt => {
		setSearchTerm(evt.target.value);
	};

	const handleFilterTopicChange = evt => {
		const topic = evt.target.closest("th").id;
		let isAscending = false;

		if (topic === filterTopic) {
			isAscending = !selectedTopic;
		}

		const topicsSort = [...displayBlogs].sort((a, b) => {
			if (a[topic] < b[topic]) return isAscending ? -1 : 1;
			if (a[topic] > b[topic]) return isAscending ? 1 : -1;
			return 0;
		});

		setDisplayBlogs(topicsSort);
		if (topic === filterTopic && selectedTopic === topic) {
			setSelectedTopic(isAscending ? topic : "");
		} else {
			setFilterTopic(topic);
			setSelectedTopic(isAscending ? topic : "");
		}
	};

	const handleShowModal = (title, id) => {
		setDisplayModal(true);
		setDeleteItem({ title, id });
	};

	const handleHideModalDisplay = () => {
		setDisplayModal(false);
	};

	const handleDelete = id => {
		axios
			.delete(`/BlogPost/${id}`)
			.then(resp => {
				if (resp.status === 200) {
					const newBlogList = blogs.filter(blog => blog.postId !== id);
					toast.success(`The article "${resp.data.title}" was deleted.`);
					setBlogs(newBlogList);
					setDisplayModal(false);
				}
			})
			.catch(err => {
				toast.error(err.message);
			});
	};

	const theadings = tableHeadings.map(heading => {
		let icon;
		if (heading.id === filterTopic) {
			icon =
				selectedTopic === heading.id ? (
					<FontAwesomeIcon icon={faArrowUpAZ} />
				) : (
					<FontAwesomeIcon icon={faArrowDownZA} />
				);
		}

		return (
			<th key={heading.id} id={heading.id} onClick={handleFilterTopicChange} className={heading.class}>
				{heading.topic} {icon}
			</th>
		);
	});

	const blogTable = displayBlogs.map(blog => {
		const modifiedDate = new Date(blog.lastModified);
		const postedDate = new Date(blog.postedOn);
		const options = { month: "short", day: "numeric", year: "numeric" };
		const modifiedTime = modifiedDate.toLocaleDateString("en-US", options);
		const postedTime = postedDate.toLocaleDateString("en-US", options);
		const postedOnDate = postedTime !== "Invalid Date" ? postedTime : "";

		return (
			<tr key={blog.postId}>
				<td>{blog.title}</td>
				<td className={styles.hideLink}>{blog.author}</td>
				<td>{blog.published ? "True" : "False"}</td>
				<td className={styles.desktopHide}>{postedOnDate}</td>
				<td className={styles.mobileHide}>{modifiedTime}</td>
				<td>
					<Link to={`/admin/articles/edit/${blog.postId}`}>
						<FontAwesomeIcon icon={faEdit} />
					</Link>
					<button
						onClick={() => handleShowModal(blog.title, blog.postId)}
						aria-label="Delete Blog Post"
						id={blog.postId}>
						<FontAwesomeIcon icon={faTrashCan} id={blog.postId} />
					</button>
				</td>
			</tr>
		);
	});

	let tableResults;

	if (loading) {
		tableResults = <LoadingIcon />;
	} else if (displayBlogs.length === 0 && searchTerm !== "") {
		tableResults = <p>Sorry, no results found. Please revise your search.</p>;
	} else if (blogs.length === 0 && !searchTerm && !serverProblem) {
		tableResults = <p>No articles found. Add an article</p>;
	} else if (serverProblem) {
		tableResults = <ServerError />;
	} else {
		tableResults = (
			<table className={styles.articlesTable}>
				<thead>
					<tr>
						{theadings}
						<th>Options</th>
					</tr>
				</thead>
				<tbody>{blogTable}</tbody>
			</table>
		);
	}

	return (
		<>
			{displayModal && <Backdrop show={displayModal} clicked={handleHideModalDisplay} />}
			{displayModal && <DeleteModal item={deleteItem} return={handleHideModalDisplay} delete={handleDelete} />}
			<h1>Articles</h1>
			<div className={styles.topRow}>
				<Link to="/admin/articles/add">
					<FontAwesomeIcon icon={faCirclePlus} /> Add an Article
				</Link>
				<form>
					<input
						type="search"
						ref={inputRef}
						onChange={handleInputChange}
						placeholder="Search titles"></input>
					<button>
						<FontAwesomeIcon icon={faMagnifyingGlass} />
					</button>
				</form>
			</div>
			{tableResults}
		</>
	);
}
