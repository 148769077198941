import { useRef, useState } from "react";
import styles from "./home-video.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faClosedCaptioning,
	faPause,
	faPlay,
	faRotateLeft,
	faVolumeHigh,
	faVolumeMute,
} from "@fortawesome/free-solid-svg-icons";

export default function HomeVideo(props) {
	const [play, setPlay] = useState(false);
	const [mute, setMute] = useState(false);
	const [max, setMax] = useState(0);
	const [progress, setProgress] = useState(0);
	const [captionsOn, setCaptionsOn] = useState(false);
	const videoRef = useRef(null);
	const progressRef = useRef(null);

	const muteIcon = mute ? faVolumeMute : faVolumeHigh;
	const playIcon = play ? faPause : faPlay;
	const hideButton = play ? styles.hide : "";
	const ariaMuteText = mute ? "Unmute" : "Mute";

	const handleVideoPlay = () => {
		if (!play) {
			videoRef.current.play();
			setPlay(true);
		} else {
			videoRef.current.pause();
			setPlay(false);
		}
	};

	const handleMute = () => {
		setMute(!mute);
	};

	const handleVideoLoaded = () => {
		setMax(videoRef.current.duration);
	}

	const handleTimeUpdated = () => {
		if (max === 0) {
			// hack for some browsers that do not fire the video loaded event
			setMax(videoRef.current.duration);
		}
		setProgress(videoRef.current.currentTime);
	}

	const handleSkipAhead = (evt) => {
		const rect = progressRef.current.getBoundingClientRect();
		const pos = (evt.pageX - rect.left) / progressRef.current.offsetWidth;
		videoRef.current.currentTime = pos* videoRef.current.duration;
	}

	const handleRestartVideo = () => {
		setCaptionsOn(false);
		setTimeout(() => {
			const video = videoRef.current;
			video.load();
			if (play) {
				video.play();
			}
			if (captionsOn) {
				setCaptionsOn(true);
			}
		}, 100);
	};

	const handleToggleCaptions = () => {
		setCaptionsOn(!captionsOn);
	};

	return (
		<div className={`col-8 ${styles.videoContainer}`}>
			<div className={styles["home-video"]}>
				<div className={styles.video} onClick={handleVideoPlay}>
					<video
						ref={videoRef}
						poster="images/HomepageVideo_ThumbnailFamily.png"
						muted={mute}
						preload="metadata"
						onLoadedMetadata={handleVideoLoaded}
						onTimeUpdate={handleTimeUpdated}>
						<source src="videos/HomepageVideo_V2.mp4"></source>
						{captionsOn && (
							<track
								src="videos/HomepageVideo_V2.vtt"
								kind="captions"
								srcLang="en"
								label="English"
								default></track>
						)}
						<p>
							Your browser doens't support HTML video. Here is a
							<a href="videos/HomepageVideo.mp4">link to the video</a> instead.
						</p>
					</video>
					<button aria-label="Play the video" className={hideButton} onClick={handleVideoPlay}>
						<img src="./images/PlayButton_FillF9921D_Stroke444444.svg" alt=""></img>
					</button>
				</div>
			</div>

			<div className={` ${styles.videoControls}`}>
				<button onClick={handleVideoPlay} aria-label="Pause the video">
					<FontAwesomeIcon icon={playIcon}></FontAwesomeIcon>
				</button>

				<button onClick={handleMute} aria-label={`${ariaMuteText} the video`}>
					<FontAwesomeIcon icon={muteIcon}></FontAwesomeIcon>
				</button>

				<button onClick={handleToggleCaptions} aria-label={"Closed Captioning"}>
					<FontAwesomeIcon icon={faClosedCaptioning}></FontAwesomeIcon>
				</button>

				<button onClick={handleRestartVideo} aria-label="Restart the video">
					<FontAwesomeIcon icon={faRotateLeft}></FontAwesomeIcon>
				</button>
			</div>
			<div>
				<progress 
					ref={progressRef} 
					value={progress} 
					min="0" 
					max={max}
					onClick={handleSkipAhead}>
				</progress>
			</div>
		</div>
	);
}
