import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import styles from './testimonial.module.css';

export default function RandomTestimonial() {

	const [item, setItem] = useState(null);

	useEffect(() => {
		axios
			.get(`/testimonial/random/1`)
			.then(resp => {
				setItem(resp.data[0]);
			})
			.catch(err => {
				toast.error(err.message);
			});
	}, []);

	let content = null;
	if (item) {
		content = (<div className={styles.testimonial}>
			<div className={styles.quote}>{item.testimonialText}</div>
			<div className={styles.byline}>- {item.clientName}</div>
		</div>);
	}

	return (<>
		{content}
	</>);

}