import { useEffect, useState } from "react";
import formBuilder from "../../../components/Forms/formBuilder";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "../locations/add-location.module.css";
import LoadingIcon from "../../../components/loading";
import ServerError from "../../../components/serverError";

export default function EditProvider() {
	const [providerForm, setProviderForm] = useState({
		firstName: formBuilder.configInput("input", "text", "First Name", null, { required: true, minLength: 2 }),
		lastName: formBuilder.configInput("input", "text", "Last Name", null, { required: true }),
		roomUrl: formBuilder.configInput("input", "text", "Room Id", null, {}),
		toLink: formBuilder.configInput("input", "text", "Url", null, { required: true }, ""),
	});
	const navigate = useNavigate();
	const [error, setError] = useState(false);
	const { id } = useParams();
	const [provider, setProvider] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [serverError, setServerError] = useState(false);

	useEffect(() => {
		if (id) {
			axios
				.get(`/Provider/${id}`)
				.then(resp => {
					setProvider(resp.data);
					setIsLoading(false);
				})
				.catch(err => {
					toast.error("There was an error loading data");
					setServerError(true);
					setIsLoading(false);
				});
		}
	}, [id]);

	useEffect(() => {
		if (provider) {
			setProviderForm({
				firstName: formBuilder.configInput(
					"input",
					"text",
					"First Name",
					null,
					{
						required: true,
						minLength: 2,
					},
					provider.firstName
				),
				lastName: formBuilder.configInput(
					"input",
					"text",
					"Last Name",
					null,
					{ required: true },
					provider.lastName
				),
				roomUrl: formBuilder.configInput("input", "text", "Room Id", null, {}, provider.roomUrl),
				toLink: formBuilder.configInput("input", "text", "Url", null, { required: true }, provider.toLink),
			});
		}
	}, [provider]);

	const handleInputChange = (evt, id) => {
		const updatedForm = { ...providerForm };
		const updatedElement = { ...updatedForm[id] };
		updatedElement.value = evt.target.value;
		updatedForm[id] = updatedElement;
		formBuilder.checkValidity(updatedElement);
		setProviderForm(updatedForm);
	};

	const handleFormSubmit = evt => {
		evt.preventDefault();

		let hasError = error ? false : false;
		for (let elt in providerForm) {
			formBuilder.checkValidity(providerForm[elt]);
			if (!providerForm[elt].valid) {
				hasError = true;
			}
		}

		if (hasError) {
			toast.error("you must properly fill out the form");
			setError(true);
			return;
		}

		const data = {
			firstName: providerForm.firstName.value,
			lastName: providerForm.lastName.value,
			roomUrl: providerForm.roomUrl.value,
			toLink: providerForm.toLink.value,
		};

		setIsLoading(true);
		axios
			.put(`/Provider/${id}`, data)
			.then(resp => {
				if (resp.status === 200) {
					toast.success(`${data.firstName} ${data.lastName} was updated`);
					navigate("/admin/providers");
				} else {
					toast.error("There was an error with your request");
					setIsLoading(false);
				}
			})
			.catch(err => {
				toast.error(err.message);
				setIsLoading(false);
			});
	};

	const form = formBuilder.buildForm(providerForm, handleInputChange);

	let providerFormDisplay;

	if (isLoading) {
		providerFormDisplay = <LoadingIcon />;
	} else if (serverError) {
		providerFormDisplay = <ServerError admin="true" />;
	} else {
		providerFormDisplay = (
			<form className={styles.locationForm}>
				{form}
				<div className={styles.btnContainer}>
					<Link to={"/admin/providers"}>Cancel</Link>
					<button onClick={handleFormSubmit}>Update Provider</button>
				</div>
			</form>
		);
	}

	return (
		<>
			<h1>Add a Provider</h1>
			{providerFormDisplay}
		</>
	);
}
