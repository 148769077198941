import { useEffect, useState } from "react";
import formBuilder from "../../../components/Forms/formBuilder";
import axios from "axios";
import styles from "./add-location.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AddState from "../../../components/adminComponents/add-state";
import Backdrop from "../../../components/Modal/backdrop";
import ServerError from "../../../components/serverError";
import LoadingIcon from "../../../components/loading";

export default function EditLocation() {
	const [locationForm, setLocationForm] = useState({
		name: formBuilder.configInput("input", "text", "Name", null, { required: true, minLength: 2 }),
		street: formBuilder.configInput("input", "text", "Street", null, { required: true }),
		suite: formBuilder.configInput("input", "text", "Suite", null, {}),
		city: formBuilder.configInput("input", "text", "City", null, {}, ""),
		stateProvinceAbbreviation: formBuilder.configInput(
			"select",
			"",
			"State",
			null,
			{
				required: true,
			},
			"",
			{ states: [] }
		),
		postalCode: formBuilder.configInput("input", "text", "Postal Code", null, { reqLength: 5 }, ""),
		phone: formBuilder.configInput("input", "text", "Phone Number", null, { required: true }, ""),
		fax: formBuilder.configInput("input", "text", "Fax", null, {}, ""),
	});
	const navigate = useNavigate();
	const { id } = useParams();
	const [states, setStates] = useState([]);
	const [error, setError] = useState(false);
	const [displayStateModal, setDisplayStateModal] = useState(false);
	const [location, setLocation] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [errorLoading, setErrorLoading] = useState(false);

	useEffect(() => {
		axios
			.get("/StateProvince")
			.then(resp => {
				const states = resp.data;
				const sortedStates = states.sort((a, b) => a.abbreviation.localeCompare(b.abbreviation));
				setStates(sortedStates);
			})
			.catch(err => {
				toast.error(err.message);
				setIsLoading(false);
				setErrorLoading(true);
			});
	}, []);

	useEffect(() => {
		axios
			.get(`/Location/${id}`)
			.then(resp => {
				setLocation(resp.data);
			})
			.catch(err => {
				toast.error(err.message);
				setIsLoading(false);
				setErrorLoading(true);
			});
	}, [id]);

	useEffect(() => {
		if (states.length > 0 && location) {
			setIsLoading(false);
			setLocationForm({
				name: formBuilder.configInput("input", "text", "Name", null, { required: true }, location.name),
				street: formBuilder.configInput("input", "text", "Street", null, { required: true }, location.street),
				suite: formBuilder.configInput("input", "text", "Suite", null, {}, location.suite),
				city: formBuilder.configInput("input", "text", "City", null, {}, location.city),
				stateProvinceAbbreviation: formBuilder.configInput(
					"select",
					"",
					"State",
					null,
					{
						required: true,
					},
					location.stateProvinceAbbreviation,
					{ states, handleAddStateModal }
				),
				postalCode: formBuilder.configInput(
					"input",
					"text",
					"Postal Code",
					null,
					{ reqLength: 5 },
					location.postalCode
				),
				phone: formBuilder.configInput(
					"input",
					"text",
					"Phone Number",
					null,
					{ required: true },
					location.phone
				),
				fax: formBuilder.configInput("input", "text", "Fax", null, {}, location.fax),
			});
		}
	}, [states, location]);

	const handleAddStateModal = evt => {
		evt.preventDefault();
		setDisplayStateModal(true);
	};

	const handleCloseStateModal = evt => {
		evt.preventDefault();
		setDisplayStateModal(false);
	};

	const successfulAddedState = data => {
		const addStates = [...states];
		addStates.push(data);
		const sortedStates = addStates.sort((a, b) => a.abbreviation.localeCompare(b.abbreviation));
		setStates(sortedStates);
		setDisplayStateModal(false);
	};

	const handleInputChange = (evt, id) => {
		const updatedForm = { ...locationForm };
		const updatedElement = { ...updatedForm[id] };
		updatedElement.value = evt.target.value;
		updatedForm[id] = updatedElement;
		formBuilder.checkValidity(updatedElement);
		setLocationForm(updatedForm);
	};

	const handleFormSubmit = evt => {
		evt.preventDefault();

		let hasError = error ? false : false;
		for (let elt in locationForm) {
			formBuilder.checkValidity(locationForm[elt]);
			if (!locationForm[elt].valid) {
				hasError = true;
			}
		}

		if (hasError) {
			toast.error("you must properly fill out the form");
			setError(true);
			return;
		}

		setIsLoading(true);

		const data = {
			name: locationForm.name.value,
			street: locationForm.street.value,
			suite: locationForm.suite.value,
			city: locationForm.city.value,
			postalCode: locationForm.postalCode.value,
			phone: locationForm.phone.value,
			fax: locationForm.fax.value,
			stateProvinceAbbreviation: locationForm.stateProvinceAbbreviation.value,
			stateProvinceId: location.stateProvinceId,
			stateProvinceName: location.stateProvinceName,
		};

		axios
			.put(`Location/${id}`, data)
			.then(resp => {
				if (resp.status === 200) {
					toast.success("Location Updated");
					navigate("/admin/locations");
				} else {
					toast.error("There was an error with your request");
					setIsLoading(false);
				}
			})
			.catch(err => {
				setIsLoading(false);
				toast.error(err.message);
			});
	};

	const form = formBuilder.buildForm(locationForm, handleInputChange);

	let displayLocationsForm;

	if (isLoading) {
		displayLocationsForm = <LoadingIcon />;
	} else if (errorLoading) {
		displayLocationsForm = <ServerError />;
	} else {
		displayLocationsForm = (
			<form className={styles.locationForm}>
				{form}
				<div className={styles.btnContainer}>
					<Link to={"/admin/locations"}>Cancel</Link>
					<button onClick={handleFormSubmit}>Update Location</button>
				</div>
			</form>
		);
	}

	return (
		<>
			{displayStateModal && <Backdrop show={displayStateModal} clicked={handleCloseStateModal} />}
			<h1>Edit Location</h1>
			{displayLocationsForm}
			{displayStateModal && <AddState close={handleCloseStateModal} success={successfulAddedState} />}
		</>
	);
}
