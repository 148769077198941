const faqData = [
	{
		mainTopic: "All",
		faqs: [
			{
				id: 1,
				title: 'What services does Anew offer?',
				content: `<p>Anew is proud to offer a variety of services proven to assist those struggling with mental health and/or substance use issues. By offering psychiatry, nursing, therapy, and case management to individuals, families, and groups, Anew can provide an integrated care plan designed to help you reach your treatment goals.</p>`,
			},
			{
				id: 2,
				title: 'What ages does Anew treat?',
				content: `<p>Anew can provide services to anyone over the age of 3.</p>`,
			},
			{
				id: 3,
				title: 'Does Anew offer medications?',
				content: `<p>When clinically appropriate, our medical team can prescribe and monitor the use of most psychotropic medications and medically assisted treatment options.</p>`,
			},
			{
				id: 4,
				title: 'What insurances does Anew accept?',
				content: `<p>Anew accepts most commercial insurance plans along with all Medicaid, Medicare, and Tricare plans. If you do not wish to use insurance coverage, self-pay options are available.</p>`,
			},
			{
				id: 5,
				title: 'How do I schedule an appointment?',
				content: `<p>You can reach our Intake and Access team at <a href="tel:+18665342639">866-534-ANEW</a> or email them at <a href="mailto:helpnow@anewbh.com">HelpNow@anewbh.com</a>.</p>`,
			},
			{
				id: 6,
				title: 'Can I attend appointments in person?',
				content: `<p>Yes. Anew offers services in person, virtually via our telehealth platforms, or you can utilize a hybrid approach with some of your services in person and others via the telehealth platforms.</p>`,
			},
			{
				id: 7,
				title: 'What is "integrated care"?',
				content: `<p>Anew believes in an approach to care that is Individualized and Integrated. Individualized care matches services to your specific needs. Unlike providers who may offer “levels” or “phases”, Anew builds individualized treatment plans based on your individual situation.</p>
				<p>Along with individual care, Anew offers integrated care. This means that you can combine psychiatry, nursing, therapy, and case management in the frequency that best meets your needs. It also means that these individual providers can work together and collaborate directly to help you achieve the best possible outcomes.</p>`,
			},
			{
				id: 8,
				title: 'How do I make a referral to Anew?',
				content: `<p>You can reach our Intake and Access team at <a href="tel:+18665342639">866-534-ANEW</a> or email them at <a href="mailto:helpnow@anewbh.com">HelpNow@anewbh.com</a>. If needed, you can also obtain a referral from the Intake and Access team.</p>`,
			},
			{
				id: 9,
				title: 'How do I connect with Anew\'s medical team?',
				content: `<p>If you have questions about a medication, need a refill, need to schedule your next follow up appointment, or just have a question for our medical team, please feel free to contact our medical team at <a href="mailto:medhelp@anewbh.com">medhelp@anewbh.com</a>.</p>`,
			},
		],
	},
];

export default faqData;
