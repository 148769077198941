import styles from "./add-article.module.css";
import {
	fontFamily,
	fontColor,
	quickToolbarSettings,
	toolbarSettings,
	fontSize,
	insertImageSettings,
	handleImageUploadSuccess,
	imageUploadingHandler
} from "../../../lib/rich-text-settings";
import { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import formBuilder from "../../../components/Forms/formBuilder";
import axios from "axios";

import {
	HtmlEditor,
	Image,
	Inject,
	Link,
	QuickToolbar,
	RichTextEditorComponent,
	Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import * as React from "react";
import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-icons/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css";
import LoadingIcon from "../../../components/loading";

function AddArticle() {
	const [editorValue, setEditorValue] = useState("");
	const navigate = useNavigate();
	const handleEditorChange = args => {
		setEditorValue(args.value);
	};
	const inputRef = useRef();
	const [editForm, setEditForm] = useState({
		title: formBuilder.configInput("input", "text", "Title", null, { required: true }, ""),
		author: formBuilder.configInput("input", "text", "Author", null, { required: true }, ""),
		primaryPhoto: formBuilder.configInput("input", "file", "Display Image", null, {}, "", {
			format: ["image/jpeg", "image/png", "image/jpg"],
			ref: inputRef,
		}),
	});
	const [error, setError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [message, setMessage] = useState("saving");

	const handleFormSubmit = evt => {
		evt.preventDefault();
		const action = evt.target.closest("button").id;
		const publish = action === "save" ? false : true;
		const urlSlug = editForm.title.value.replaceAll(" ", "-").toLowerCase();

		let hasError = error ? false : false;
		for (let elt in editForm) {
			formBuilder.checkValidity(editForm[elt]);
			if (!editForm[elt].valid) {
				hasError = true;
			}
		}

		if (hasError) {
			toast.error("you must properly fill out the form");
			setError(true);
			return;
		}

		const currentDate = new Date();
		const postedOn = publish ? currentDate.toISOString() : "";

		const data = {
			title: editForm.title.value,
			body: editorValue,
			urlSlug: urlSlug,
			published: publish,
			author: editForm.author.value,
			primaryPhoto: editForm.primaryPhoto.options.ref.current.files[0],
			postedOn,
		};

		const message = publish ? "Article Published" : "Article Saved";

		const options = {
			headers: { "content-type": "multipart/form-data" },
		};

		setIsLoading(true);
		if (publish) {
			setMessage("publish");
		} else {
			setMessage("saving");
		}
		axios
			.post(`/BlogPost`, data, options)
			.then(resp => {
				if (resp.status === 201) {
					toast.success(message);
					if (action === "submit") {
						navigate("/admin/articles");
						return;
					}
					navigate(`/admin/articles/edit/${resp.data.postId}`);
				} else {
					toast.error(resp.message);
					setIsLoading(false);
				}
			})
			.catch(err => {
				toast.error(err);
				setIsLoading(false);
			});
	};

	const handleInputChange = (evt, id) => {
		const updatedForm = { ...editForm };
		const updatedElement = { ...updatedForm[id] };
		updatedElement.value = evt.target.value;
		updatedForm[id] = updatedElement;
		formBuilder.checkValidity(updatedElement);
		setEditForm(updatedForm);
	};

	const form = formBuilder.buildForm(editForm, handleInputChange);

	const articleForm = !isLoading ? (
		<form>
			{form}

			<RichTextEditorComponent
				value={editorValue}
				fontSize={fontSize}
				fontFamily={fontFamily}
				fontColor={fontColor}
				change={handleEditorChange}
				className={styles.richArea}
				height={450}
				toolbarSettings={toolbarSettings}
				quickToolbarSettings={quickToolbarSettings}
				insertImageSettings={insertImageSettings}
				imageUploadSuccess={handleImageUploadSuccess.bind(this)}
				imageUploading={imageUploadingHandler.bind(this)}
				>
				<Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]} />
			</RichTextEditorComponent>
			<div className={styles.textButtons}>
				<NavLink to="/admin/articles">Cancel</NavLink>
				<div>
					<button id="save" onClick={handleFormSubmit}>
						<FontAwesomeIcon icon={faSave} />
						Save Draft
					</button>
					<button id="submit" onClick={handleFormSubmit}>
						<FontAwesomeIcon icon={faPaperPlane} />
						Publish Article
					</button>
				</div>
			</div>
		</form>
	) : (
		<LoadingIcon message={message} />
	);

	return (
		<div className={styles.addContainer}>
			<h1>Add an Article</h1>
			{articleForm}
		</div>
	);
}
export default AddArticle;
