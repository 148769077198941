import styles from './careers.module.css'


function Careers() {

	return (
		<div className={styles.careersWrapper}>
			<h1 className={styles.rotatingTitle}>Do you want to be a part of something&nbsp;
				<div>
					<div>
						<span>positive?</span>
						<span>supportive?</span>
						<span>fun?</span>
					</div>
				</div>
			</h1>
			<div className={styles.topRow}>
				<div>
					
					<h2>Join the Anew Team</h2>
					<p className="mtop-none">Anew is owned and operated exclusively by clinicians. Our goal is to create the most provider friendly work environment. We are a certified Recovery Friendly Workplace! Leadership “gets us” because they “are us”.</p>
				</div>
				<div>
					<img src="/images/acorn_jointheteam.svg" alt="acorn mascot" />
				</div>
			</div>
			

			<section className={styles.section}>
				<div className={styles.row}>
					<div>
						<h3>Flexible Work Options</h3>
						<p>Anew offers flexible work options.</p>
						<p>You choose from:
							<ul className="bulleted">
								<li>Full time</li>
								<li>Part time</li>
								<li>Contract positions</li>
							</ul>
						</p>
						<p>You decide when you want to work. All Anew providers enjoy the freedom to set their own schedules and the work hours that work for you!</p>
					</div>
					<div>
						<h3>Compensation Packages</h3>
						<p>Anew offers industry leading compensation packages.</p>
						<p>You choose from:
							<ul className="bulleted">
								<li>Top of the range financial considerations</li>
								<li>A wide variety of benefits and disability plans</li>
							</ul>
						</p>
						<p>You have access to:
							<ul className="bulleted">
								<li>PTO and Mental Health leave</li>
								<li>Holiday bonuses</li>
								<li>Free CEUs</li>
							</ul>
						</p>
						<p>Your low deductible Medical and Dental plans begin the first month after your start date.</p>
					</div>
					<div>
						<h3>A Fun Culture</h3>
						<p>Anew offers a supportive team-based environment that makes it easy to have fun together while we change lives in our communities.</p>
						<p>You can be involved in:
							<ul className="bulleted">
								<li>Connection Committee Events</li>
								<li>CEU Events</li>
								<li>Community outreach</li>
								<li>Charity initiatives</li>
								<li>Food Drives</li>
								<li>Holiday Events</li>
								<li>Team Parties</li>
								<li>Monthly “First Friday” all staff events.</li>
							</ul>
						</p>
					</div>
				</div>

				<div>
					<p className="text-center">Interested in becoming an Anewbie? Contact Human Resources at <a href="mailto:anewhr@anewbh.com">anewhr@anewbh.com</a>.</p>
					<div className="text-center">
						<a className={styles.teamLink} href="mailto:anewhr@anewbh.com">Join the Team</a>
					</div>
				</div>
			</section>
		</div>
	);
}

export default Careers;
