import React from "react";
import styles from "./Input.module.css";

const Input = props => {
	let elem = null;

	const classes = [];
	if (props.attrs.className) classes.push(props.attrs.className.replace("invalid", "").trim());
	if (props.valid === false) classes.push("invalid");
	props.attrs.className = classes.join(" ").trim();

	switch (props.kind) {
		case "select":
			elem = (
				<>
					<select id={props.label} required {...props.attrs} onChange={props.changed} value={props.value}>
						<option value="">State</option>
						{props.options.states.map(element => {
							return (
								<option key={element.stateProvinceId} value={element.abbreviation}>
									{element.abbreviation}
								</option>
							);
						})}
					</select>
					<button onClick={props.options.handleAddStateModal}>Missing a state? Click to add one.</button>
				</>
			);
			break;
		case "textarea":
			elem = <textarea id={props.label} {...props.attrs} value={props.value} onChange={props.changed}></textarea>;
			break;
		default:
			if (props.attrs.type === 'checkbox') {
				elem = (
					<input
						id={props.label}
						{...props.attrs}
						checked={props.value}
						onChange={props.changed}
						{...props.options}
					/>
				);
			} else {
				elem = (
					<input
						id={props.label}
						{...props.attrs}
						value={props.value}
						onChange={props.changed}
						{...props.options}
					/>
				);
			}
			
	}

	const errors = [];
	if (props.errors) {
		for (let key in props.errors) {
			errors.push(props.errors[key]);
		}
	}

	let errorContent = null;
	if (errors.length) {
		const errMsgs = errors.map((err, idx) => <li key={idx}>{err}</li>);
		errorContent = <ul className="validation-errors">{errMsgs}</ul>;
	}

	let labelText = props.label;
	if (props.required) {
		labelText += ' *';
	}

	return (
		<div className={styles.input}>
			<label htmlFor={props.label}>{labelText}</label>
			{elem}
			{errorContent}
		</div>
	);
};

export default Input;
