import React from 'react';

const AuthContext = React.createContext({
	authInfo: {},
	setAuthInfo: () => {},
	clearAuthInfo: () => {},
	isLoggedIn: () => {}
});

export default AuthContext;
