import React from "react";
import Input from "./Input";

const formBuilder = {
	configInput(kind, type, label, placeholder, validation = {}, value = '', options = {}) {
		const settings = { kind, label, value, validation, attrs: {}, options };

		if (typeof type !== "undefined") {
			settings.attrs.type = type;
		}

		if (placeholder !== null && typeof placeholder !== "undefined") {
			settings.attrs.placeholder = placeholder;
		} else {
			settings.attrs.placeholder = "enter " + label.toLowerCase();
		}

		if (kind === 'input' && type === 'checkbox' && settings.value === '') {
			settings.value = false;
		}

		return settings;
	},

	buildForm(settings, handler) {
		const elements = [];
		for (let key in settings) {
			elements.push({ id: key, config: settings[key] });
		}

		const content = elements.map(elem => (
			<Input
				key={elem.id}
				label={elem.config.label}
				kind={elem.config.kind}
				value={elem.config.value}
				attrs={elem.config.attrs}
				changed={event => handler(event, elem.id)}
				valid={elem.config.valid}
				errors={elem.config.errors}
				options={elem.config.options}
				required={!!elem.config.validation.required}
			/>
		));

		return content;
	},

	checkValidity(inputConfig, entireForm) {
		inputConfig.valid = true;
		inputConfig.errors = {};

		if (inputConfig.validation.required && inputConfig.kind !== "select") {
			if (inputConfig.value.trim() === "") {
				inputConfig.valid = false;
				if (inputConfig.attrs.type === "file") {
					inputConfig.errors.required = "You must upload a file";
				} else {
					inputConfig.errors.required = `You must enter a value for ${inputConfig.label}.`;
				}
			}
		}

		if (inputConfig.validation.minLength && inputConfig.value) {
			if (inputConfig.value.trim().length < inputConfig.validation.minLength) {
				inputConfig.valid = false;
				inputConfig.errors.minLength = `${inputConfig.label} must be at least ${inputConfig.validation.minLength} characters.`;
			}
		}

		if (inputConfig.validation.reqLength && inputConfig.value) {
			if (inputConfig.value.trim().length !== inputConfig.validation.reqLength) {
				inputConfig.valid = false;
				inputConfig.errors.reqLength = `${inputConfig.label} must contain ${inputConfig.validation.reqLength} characters.`;
			}
		}

		if (inputConfig.validation.sameAs && inputConfig.value && entireForm) {
			const otherPropName = inputConfig.validation.sameAs;
			const otherPropConfig = entireForm[otherPropName];

			if (otherPropConfig) {
				if (inputConfig.value !== otherPropConfig.value) {
					inputConfig.valid = false;
					inputConfig.errors.sameAs = `${inputConfig.label} is not the same as ${otherPropConfig.label}.`;
				}
			}
		}

		if (inputConfig.attrs.type === "email" && inputConfig.value) {
			let re =
				/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?:[A-Z]{2}|com|org|net|gov|mil|biz|info|mobi|name|aero|jobs|museum)\b/;
			if (!re.test(inputConfig.value.toLowerCase())) {
				inputConfig.valid = false;
				inputConfig.errors.email = `${inputConfig.label} must be a valid email address`;
			}
		}
	},
};
export default formBuilder;
