import { useContext } from "react";
import { clearAuth } from "../../services/authService"
import styles from './logout-form.module.css'
import AuthContext from "../../context/auth-context";

export default function LogoutForm(props) {

	const authContext = useContext(AuthContext);

	const handleLogout = () => {
		clearAuth();
		authContext.clearAuthInfo();
	};

	return (
		<div className={styles.accountContainer}>
			<h1>Log Out</h1>
			<div className={styles.logButtonContainer}>
				<button onClick={handleLogout}>Log Out</button>
			</div>
		</div>
	);
}