import React from "react";
import howHelp from "../../../lib/how-help-data";
import { Link } from "react-router-dom";
import styles from "./how-help.module.css";

export default function HowHelp() {
	return (
		<section className={styles.title}>
			<h1>How We Can Help</h1>
			<div id="howHelpContainer" className={styles.howHelpContainer}>
				{howHelp.map(topic => {
					return (
						<div key={topic.id} id={topic.id}>
							<Link
								to={`/how-we-can-help/${topic.shortUrl}`}
								className={`how-help-button ${topic.color}`}>
								<h2>{topic.topic}</h2>
							</Link>
						</div>
					);
				})}
			</div>
		</section>
	);
}
