import styles from "../articles/articlesDashboard.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCirclePlus,
	faMagnifyingGlass,
	faEdit,
	faTrashCan,
	faArrowDownZA,
	faArrowUpAZ,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import LoadingIcon from "../../../components/loading";
import Backdrop from "../../../components/Modal/backdrop";
import DeleteModal from "../../../components/adminComponents/delete-modal";

const tableHeadings = [
	{ topic: "First Name", id: "firstName" },
	{ topic: "Last Name", id: "lastName" },
	{ topic: "Room Id", id: "roomUrl" },
	{ topic: "Link", id: "link" },
];

export default function ProvidersDashboard() {
	const [providers, setProviders] = useState([]);
	const [displayProviders, setDisplayProviders] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const inputRef = useRef(null);
	const [loading, setLoading] = useState(true);
	const [selectedTopic, setSelectedTopic] = useState("lastName");
	const [displayModal, setDisplayModal] = useState(false);
	const [deleteItem, setDeleteItem] = useState(null);
	const [filterTopic, setFilterTopic] = useState("lastName");

	useEffect(() => {
		axios
			.get("/Provider")
			.then(resp => {
				const sortFirstName = resp.data.sort((a, b) => a.firstName.localeCompare(b.firstName));
				const sortLastName = sortFirstName.sort((a, b) => a.lastName.localeCompare(b.lastName));

				setProviders(sortLastName);
				setDisplayProviders(sortLastName);
				setLoading(false);
			})
			.catch(err => {
				toast.error(err.message);
				setLoading(false);
			});
	}, []);

	useEffect(() => {
		const timerId = setTimeout(() => {
			if (inputRef.current.value === searchTerm) {
				const displayBlogs = providers.filter(
					provider =>
						searchTerm === "" ||
						provider.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
						provider.lastName.toLowerCase().includes(searchTerm) ||
						provider.roomUrl.includes(searchTerm)
				);
				setDisplayProviders(displayBlogs);
			}
		}, 500);

		return () => clearTimeout(timerId);
	}, [searchTerm, providers]);

	const handleSort = evt => {
		const topic = evt.target.closest("th").id;
		let isAscending = false;
		if (topic === filterTopic) {
			isAscending = !selectedTopic;
		}

		let sort = [...displayProviders];

		const topicsSort = sort.sort((a, b) => {
			if (a[topic] < b[topic]) return isAscending ? -1 : 1;
			if (a[topic] > b[topic]) return isAscending ? 1 : -1;
			return 0;
		});
		setDisplayProviders(topicsSort);
		if (topic === filterTopic && selectedTopic === topic) {
			setSelectedTopic(isAscending ? topic : "");
		} else {
			setFilterTopic(topic);
			setSelectedTopic(isAscending ? topic : "");
		}
	};

	const handleDelete = id => {
		axios
			.delete(`/Provider/${id}`)
			.then(resp => {
				if (resp.status === 200) {
					const newLocationList = providers.filter(provider => provider.providerId !== id);
					setDisplayProviders(newLocationList);
					setDisplayModal(false);
					toast.success(`${resp.data.firstName} ${resp.data.lastName} was deleted`);
				}
			})
			.catch(err => {
				toast.error(err);
			});
	};

	const handleHideModalDisplay = () => {
		setDisplayModal(false);
	};

	const handleShowModal = (name, id) => {
		setDisplayModal(true);
		setDeleteItem({ title: name, id });
	};

	const handleInputChange = evt => {
		setSearchTerm(evt.target.value.toLowerCase());
	};

	const providersTable = displayProviders.map(provider => (
		<tr key={provider.providerId}>
			<td>{provider.firstName}</td>
			<td>{provider.lastName}</td>
			<td className={styles.mobileHide}>{provider.roomUrl}</td>
			<td className={styles.hideLink}>{provider.toLink}</td>
			<td>
				<Link to={`/admin/providers/edit/${provider.providerId}`}>
					<FontAwesomeIcon icon={faEdit} />
				</Link>
				<button
					onClick={() => handleShowModal(`${provider.firstName} ${provider.lastName}`, provider.providerId)}
					aria-label="Delete Blog Post">
					<FontAwesomeIcon icon={faTrashCan} />
				</button>
			</td>
		</tr>
	));

	const theadings = tableHeadings.map(heading => {
		let icon;
		if (heading.id === "link") {
			return (
				<th key={heading.id} id={heading.id} className={styles.hideLink}>
					{heading.topic}
				</th>
			);
		} else if (heading.id === "roomUrl") {
			return (
				<th key={heading.id} id={heading.id} className={styles.mobileHide}>
					{heading.topic}
				</th>
			);
		}

		if (heading.id === filterTopic) {
			icon =
				selectedTopic === heading.id ? (
					<FontAwesomeIcon icon={faArrowUpAZ} />
				) : (
					<FontAwesomeIcon icon={faArrowDownZA} />
				);
		}

		return (
			<th key={heading.id} id={heading.id} onClick={handleSort}>
				{heading.topic} {icon}
			</th>
		);
	});

	let tableResults;

	if (loading) {
		tableResults = <LoadingIcon />;
	} else if (displayProviders.length === 0 && searchTerm !== "") {
		tableResults = <p>Sorry, no results found. Please revise your search</p>;
	} else if (providers.length === 0 && !searchTerm) {
		tableResults = <p>No providers found. Add a Provider</p>;
	} else {
		tableResults = (
			<table className={styles.articlesTable}>
				<thead>
					<tr>
						{theadings}
						<th>Options</th>
					</tr>
				</thead>
				<tbody>{providersTable}</tbody>
			</table>
		);
	}

	return (
		<>
			{displayModal && <Backdrop show={displayModal} clicked={handleHideModalDisplay} />}
			{displayModal && <DeleteModal item={deleteItem} return={handleHideModalDisplay} delete={handleDelete} />}
			<h1>Providers</h1>
			<div className={styles.topRow}>
				<Link to="/admin/providers/add">
					<FontAwesomeIcon icon={faCirclePlus} /> Add a Provider
				</Link>
				<form>
					<input
						type="search"
						ref={inputRef}
						onChange={handleInputChange}
						placeholder="Search providers"></input>
					<button>
						<FontAwesomeIcon icon={faMagnifyingGlass} />
					</button>
				</form>
			</div>
			{tableResults}
		</>
	);
}
