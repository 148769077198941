import React, { useState, useEffect, useRef } from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import ScrollBar from "../../../components/scrollbar";
import styles from "./locations.module.css";
import axios from "axios";
import Map from "../../../components/map";
import LoadingIcon from "../../../components/loading";
import ServerError from "../../../components/serverError";

export default function Locations() {
	const [locations, setLocations] = useState([]);
	const [serverError, setServerError] = useState(false);
	const [loading, setLoading] = useState(true);
	const [displayLocations, setDisplayLocations] = useState([]);
	const [stateFocus, setStateFocus] = useState("OH");
	const [searchTerm, setSearchTerm] = useState("");
	const [selectedLocation, setSelectedLocation] = useState("");
	const inputRef = useRef();

	useEffect(() => {
		if (!locations.length) {
			axios
				.get(`/Location`)
				.then(resp => {
					const sortName = resp.data.sort((a, b) => a.name.localeCompare(b.name));
					const sortState = sortName.sort((a, b) =>
						a.stateProvinceAbbreviation.localeCompare(b.stateProvinceAbbreviation)
					);
					setLocations(sortState);
					setLoading(false);
				})
				.catch(err => {
					setLoading(false);
					setServerError(true);
				});
		}
	}, [locations]);

	useEffect(() => {
		if (locations.length) {
			setDisplayLocations(locations.filter(location => location.stateProvinceAbbreviation === stateFocus));
		}
	}, [stateFocus, locations]);

	const handleSearchTerm = event => {
		event.preventDefault();

		const filter = locations.filter(
			location =>
				location.city.toLowerCase().includes(searchTerm) ||
				location.stateProvinceName.toLowerCase().includes(searchTerm) ||
				location.stateProvinceAbbreviation.toLowerCase().includes(searchTerm)
		);

		setDisplayLocations(filter);
		setSelectedLocation("");
	};

	const handleInputChange = event => {
		setSearchTerm(event.target.value.toLowerCase());
	};

	const handleStateClick = event => {
		const closestButton = event.target.closest("button");
		setSelectedLocation("");
		setStateFocus(closestButton.id);
	};

	const handleSelectedLocation = location => {
		setSelectedLocation(location);
	};

	if (loading) return <LoadingIcon />;

	if (serverError) return <ServerError />;

	return (
		<>
			<section id="locations-page" className={`${styles.grid} ${styles["locations-page"]}`}>
				<h1 className={`col-3 ${styles["locations-title"]}`}>Locations</h1>
				<div className={`col-9 row justify-center ${styles["locations-buttons"]}`}>
					<button onClick={handleStateClick} id="NH">
						<span className={stateFocus === "NH" ? "underline" : ""}>New Hampshire</span>
					</button>
					<button onClick={handleStateClick} id="OH">
						<span className={stateFocus === "OH" ? "underline" : ""}>Ohio</span>
					</button>
				</div>
				<div className={`${styles.searchBar}`}>
					<form onSubmit={handleSearchTerm}>
						<input
							type="search"
							aria-label="Search for location by typing a city or state"
							name="Search"
							placeholder="Type a city or state."
							ref={inputRef}
							onChange={handleInputChange}></input>
						<button type="submit" aria-label="Submit Search">
							<FontAwesomeIcon icon={faMagnifyingGlass} size="2xl" color="#033F63" />
						</button>
					</form>
				</div>
				<div className={`${styles.locationsSidebar}`}>
					<aside>
						<div id="locations">
							<ScrollBar locations={displayLocations} selectLocation={handleSelectedLocation} />
						</div>
					</aside>
				</div>
				<div className={`map-side`}>
					<Wrapper apiKey={process.env.REACT_APP_MAPS_KEY}>
						<Map locations={displayLocations} selectLocation={selectedLocation}></Map>
					</Wrapper>
				</div>
			</section>
		</>
	);
}
