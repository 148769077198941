import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./delete-modal.module.css";
import { faTrashCan, faX } from "@fortawesome/free-solid-svg-icons";

export default function deleteModal(props) {
	const { item } = props;

	return (
		<div className={styles.deleteModal}>
			<div>
				<button onClick={props.return}>
					<FontAwesomeIcon icon={faX} />
				</button>
			</div>
			<FontAwesomeIcon icon={faTrashCan} />
			<p>Are you sure you want to delete "{item.title}"?</p>
			<div className={styles.btnContainer}>
				<button onClick={props.return}>Go Back</button>
				<button onClick={() => props.delete(item.id)}>Delete</button>
			</div>
		</div>
	);
}
