import { Link } from "react-router-dom";
import styles from "./home.module.css";
import HomeVideo from "../../components/home-video";
import RandomTestimonial from "../../components/testimonials/random-testimonial";

export default function Homepage() {


	return (
		<>
			<section className={styles['cta-wrapper']}>
				<div className={styles['cta-text']}>
					<p>Anew offers Instant Access! If you are interested in Beginning Anew, or you would like to make a referral, or you just have a few questions, click below to be connected to a member of the Intake and Access team.</p>
				</div>
				<div className={styles.cta}>
					<a href="https://anewbh.doxy.me/intake1" 
						className={styles.instantAccess} 
						target="_blank" 
						rel="noreferrer"><i class="fa-solid fa-video"></i>Instant Access</a>
				</div>
			</section>
			<section className="row wrap" id="aboutAnewSection">
				<HomeVideo />

				<div id="anew-bio" className={`${styles["anew-bio"]}`}>
					<div>
						<h1 className={styles["explanation-heading"]}>Who Is Anew?</h1>
						<p>
							Anew is an outpatient behavioral health provider that helps individuals facing mental health and substance use disorders.
						</p>
					</div>

					<div>
						<h2 className={styles["explanation-heading"]}>The Anew Mission</h2>
						<p>
							The mission of Anew Behavioral Health is to provide life changing care to all that we serve. Anew was built by clinicians and is operated exclusively by clinicians who are committed to providing positive, strength-based, integrated and individualized solutions to best serve you and your loved ones.
						</p>
					</div>
					<div>
						<h2 className={styles["explanation-heading"]}>The Anew Vision</h2>
						<p>Anew is committed to creating a world without stigma and a life without limits. In our world, every day is an opportunity for a fresh start, and anyone can Begin Anew.</p>
					</div>

				</div>
			</section>
			
			<section className={styles["telehealth-section"]}>
				<div>
					<img src="/images/telehealthimage.svg" alt="drawing of hand holding phone using Anew Telehealth services" />
				</div>
				<div>
					<div className={styles["telehealth-heading"]}>
						<h2 className={styles["explanation-heading"]}>Telehealth with Anew</h2>
						<div>
							<Link to={"/telehealth"} className={styles.teleHealthLink}>
								Start Call
							</Link>
						</div>
					</div>

					<p>We offer telehealth services to patients who have busy schedules or are looking to seek care in the comfort of their own home.  All telehealth services require appointments to be scheduled in advance through our intake and access teams.</p>

					<p>Need help scheduling an appointment? Call <a href="tel:+18665342639">866-534-ANEW</a> or email us at <a href="mailto:helpnow@anewbh.com">helpnow@anewbh.com</a>.</p>

					<p>Already have an appointment? Connect to your provider by clicking the “Start Call” button.</p>
				</div>
				
			</section>

			<section className={styles["podcast-section"]}>
				<div>
					<img src="/images/podcastimage.svg" alt="drawing of Jayson Pratt recording a podcast" />
				</div>
				<div>
					<h2 className={styles["explanation-heading"]}>Our Podcast: Through Help &amp; Back</h2>
					<p><a href="https://helpandback.com/" target="_blank" rel="noreferrer">THAB</a> is focused on current issues and treatment options related to mental health and substance use and is hosted by our CEO, Jayson Pratt. <a href="https://www.youtube.com/@helpandback" target="_blank" rel="noreferrer">Listen now</a></p>
				</div>
			</section>

			<RandomTestimonial></RandomTestimonial>
		</>
	);
}
