import styles from "./add-article.module.css";
import { useState, useEffect } from "react";
import {
	fontFamily,
	fontColor,
	quickToolbarSettings,
	toolbarSettings,
	fontSize,
	handleImageUploadSuccess,
	insertImageSettings,
	imageUploadingHandler,
} from "../../../lib/rich-text-settings";
import { useNavigate, useParams, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { faSave, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import {
	HtmlEditor,
	Image,
	Inject,
	Link,
	QuickToolbar,
	RichTextEditorComponent,
	Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import * as React from "react";
import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-icons/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
import "../../../../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css";
import formBuilder from "../../../components/Forms/formBuilder";
import ServerError from "../../../components/serverError";
import LoadingIndicator from "../../../components/UI/BusySpinner";

function EditArticle() {
	const [editorValue, setEditorValue] = useState("");
	const [article, setArticle] = useState(null);
	const { id } = useParams();
	const navigate = useNavigate();
	const [editForm, setEditForm] = useState({
		title: formBuilder.configInput("input", "text", "Title", null, { required: true }, ""),
		author: formBuilder.configInput("input", "text", "Author", null, { required: true }, ""),
		primaryPhoto: formBuilder.configInput("input", "file", "Choose a new display image for article", null, {}, "", {
			format: "image/*",
		}),
	});
	const [error, setError] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [serverError, setServerError] = useState(false);

	useEffect(() => {
		axios
			.get(`/BlogPost/${id}`)
			.then(resp => {
				setArticle(resp.data);
				setIsLoading(false);
			})
			.catch(err => {
				setIsLoading(false);
				setServerError(true);
			});
	}, [id]);

	useEffect(() => {
		if (article) {
			setEditorValue(article.body);
			setEditForm({
				title: formBuilder.configInput("input", "text", "Title", null, { required: true }, article.title),
				author: formBuilder.configInput("input", "text", "Author", null, { required: true }, article.author),
				primaryPhoto: formBuilder.configInput(
					"input",
					"file",
					"Choose a new display image for article",
					null,
					{},
					"",
					{
						format: "image/*",
					}
				),
			});
		}
	}, [article]);

	const handleEditorChange = args => {
		setEditorValue(args.value);
	};

	const handleInputChange = (evt, id) => {
		const updatedForm = { ...editForm };
		const updatedElement = { ...updatedForm[id] };
		updatedElement.value = evt.target.value;
		updatedForm[id] = updatedElement;
		formBuilder.checkValidity(updatedElement);
		setEditForm(updatedForm);
	};

	const handleFormSubmit = evt => {
		evt.preventDefault();

		let hasError = error ? false : false;
		for (let elt in editForm) {
			formBuilder.checkValidity(editForm[elt]);
			if (!editForm[elt].valid) {
				hasError = true;
			}
		}

		if (hasError) {
			toast.error("you must properly fill out the form");
			setError(true);
			return;
		}

		const action = evt.target.closest("button").id;
		const publish = action === "save" ? false : true;
		const urlSlug = editForm.title.value.replaceAll(" ", "-").toLowerCase();
		const currentDate = new Date();
		let postedOn;
		if (!article.postedOn && publish) {
			postedOn = currentDate.toISOString();
		} else {
			postedOn = article.postedOn;
		}

		const data = {
			author: editForm.author.value,
			title: editForm.title.value,
			urlSlug: urlSlug,
			body: editorValue,
			published: publish,
			primaryPhoto: editForm.primaryPhoto.value,
			postedOn,
		};

		const message = publish ? "Content Published" : "Content Saved";
		const options = {
			headers: { "content-type": "multipart/form-data" },
		};

		setIsLoading(true);

		axios
			.put(`/BlogPost/${id}`, data, options)
			.then(resp => {
				if (resp.status === 200) {
					toast.success(message);
					if (action === "submit") navigate("/admin/articles");
					setError(false);
					setIsLoading(false);
				} else {
					toast.error(resp.message);
					setIsLoading(false);
				}
			})
			.catch(err => {
				toast.error(err.message);
				setIsLoading(false);
			});
	};

	const form = formBuilder.buildForm(editForm, handleInputChange);

	let serverMessages;
	if (isLoading) serverMessages = <LoadingIndicator />;
	if (serverError) serverMessages = <ServerError admin="true" />;

	const thumbnailImage =
		article && editForm.primaryPhoto.value === "" ? (
			<div className={styles.displayPhoto}>
				<p>Current display image:</p>
				<img src={`data:image/*;base64,${article.primaryPhoto}`} alt={`Display for "${article.title}"`} />
			</div>
		) : null;

	return (
		<div className={styles.addContainer}>
			<h1>Edit Article</h1>
			{serverMessages}
			<form onSubmit={handleFormSubmit}>
				{form}
				{thumbnailImage}
				<RichTextEditorComponent
					value={editorValue}
					fontSize={fontSize}
					fontFamily={fontFamily}
					fontColor={fontColor}
					change={handleEditorChange}
					className={styles.richArea}
					height={500}
					insertImageSettings={insertImageSettings}
					imageUploadSuccess={handleImageUploadSuccess}
					imageUploading={imageUploadingHandler.bind(this)}
					toolbarSettings={toolbarSettings}
					quickToolbarSettings={quickToolbarSettings}>
					<Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]} />
				</RichTextEditorComponent>
				<div className={styles.textButtons}>
					<NavLink to="/admin/articles">Cancel</NavLink>
					<div>
						<button id="save" onClick={handleFormSubmit}>
							<FontAwesomeIcon icon={faSave} />
							Save Draft
						</button>
						<button id="submit" onClick={handleFormSubmit}>
							<FontAwesomeIcon icon={faPaperPlane} />
							Publish Article
						</button>
					</div>
				</div>
			</form>
		</div>
	);
}
export default EditArticle;
