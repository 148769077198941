import React, { useState, useCallback } from "react";
import { BrowserRouter } from "react-router-dom";
import ContentContainer from "./ContentContainer";
import NavigationBar from "./components/navigation-bar";
import ScrollToTop from "./ScrollToTop";
import "./App.css";
import AuthContext from "./context/auth-context";
import { defaultAuthValue } from "./services/authService";

function App() {

	const [renderKey, setRenderKey] = useState({ key: 0});

	const setAuthInfo = useCallback((token) => {
		window.localStorage.setItem('authUser', JSON.stringify(token));
		setRenderKey({ key: Math.random()});
	}, []);

	const getAuthInfo = useCallback(() => {
		let value;
		const test = window.localStorage.getItem('authUser');
		if (test) {
			try {
				value = JSON.parse(test);
			}
			catch {
				value = defaultAuthValue;
			}
		}
		else {
			value = defaultAuthValue;
		}
		return value;
	}, []);

	const checkLoginNotExpired = useCallback(() => {
		const authInfo = getAuthInfo();
		if (!authInfo.token) return false;

		if (!authInfo.expiration || authInfo.expiration < new Date()) return false;

		return true;
	}, [getAuthInfo]);

	const clearAuthInfo = useCallback(() => {
		setAuthInfo(defaultAuthValue);
	}, [setAuthInfo]);

	const authInfo = getAuthInfo();
	return (
		<AuthContext.Provider value={{
			authInfo: authInfo,
			setAuthInfo: setAuthInfo,
			clearAuthInfo: clearAuthInfo,
			isLoggedIn: checkLoginNotExpired
		}}>
			<BrowserRouter key={renderKey}>
				<NavigationBar />
				<ContentContainer />
				<ScrollToTop />
				
			</BrowserRouter>
		</AuthContext.Provider>
	);
}

export default App;
