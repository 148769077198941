import { Link } from "react-router-dom";
import styles from "./not-found.module.css";

export default function NotFound() {
	return (
		<div className={styles["notFound-container"]}>
			<h1>Oops...</h1>
			<p>We lost the page you were looking for.</p>
			<img src="/images/Img_404Acorn.svg" alt="Acorn with missing cupule"></img>
			<p>Don't worry, we are still here to help!</p>
			<p>
				Head back to the <Link to={"/"}>homepage</Link> or contact us <Link to={"/contact"}>here</Link>
			</p>
		</div>
	);
}
