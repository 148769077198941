import React, { useState, useContext } from "react";
import styles from "./navigation-bar.module.css";
import { Link, NavLink, useLocation } from "react-router-dom";
import AuthContext from "../context/auth-context";

const linkTitles = [
	{ link: "/", title: "Home" },
	{ link: "/how-we-can-help", title: "How We Can Help" },
	{ link: "/our-team", title: "Our Team" },
	{ link: "/locations", title: "Locations" },
	{ link: "/faqs", title: "FAQs" },
	// { link: "/articles", title: "Articles" },
	// { link: "/careers", title: "Join the Team" },
	{ link: "/contact", title: "Contact" },
];

const adminLinks = [
	{ link: "/admin/dashboard", title: "Admin Dashboard" },
	{ link: "/admin/articles", title: "Articles" },
	{ link: "/admin/providers", title: "Providers" },
	{ link: "/admin/locations", title: "Locations" },
	{ link: "/admin/testimonials", title: "Testimonials" },
	{ link: "/admin/account", title: "Account" },
	{ link: "/", title: "Site Home" },
];
const minAdminLinks = [
	{ link: "/", title: "Site Home" },
];

export default function NavigationBar() {
	const [toggleMenu, setToggleMenu] = useState(false);
	const location = useLocation();
	const handleMenuToggle = () => {
		setToggleMenu(!toggleMenu);
	};
	const authContext = useContext(AuthContext);

	const btnImg = toggleMenu ? styles.xmark : "";

	let linkItems;

	if (location.pathname.includes("/admin")) {
		if (authContext.isLoggedIn()) {
			linkItems = adminLinks.map(page => {
				return (
					<li key={page.title} onClick={handleMenuToggle} className={styles.admin}>
						<NavLink to={`${page.link}`}>
							<span className={`${styles["nav-underline"]}`}>{page.title}</span>
						</NavLink>
					</li>
				);
			});
		}
		else {
			linkItems = minAdminLinks.map(page => {
				return (
					<li key={page.title} onClick={handleMenuToggle} className={styles.admin}>
						<NavLink to={`${page.link}`}>
							<span className={`${styles["nav-underline"]}`}>{page.title}</span>
						</NavLink>
					</li>
				);
			});
		}
		
	} else {
		linkItems = linkTitles.map(page => {
			if (page.title === "Home") {
				return (
					<li key={page.title} className="xmark" onClick={handleMenuToggle}>
						<NavLink to={`${page.link}`}>
							<span className={`${styles["nav-underline"]}`}>{page.title}</span>
						</NavLink>
					</li>
				);
			}

			return (
				<li key={page.title} onClick={handleMenuToggle}>
					<NavLink to={`${page.link}`}>
						<span className={`${styles["nav-underline"]}`}>{page.title}</span>
					</NavLink>
				</li>
			);
		});
	}

	return (
		<header id="navigationBar" className={styles["navigation-bar"]}>
			<div className={`container-width row align-content-center ${styles["navigation-container"]}`}>
				<div className={styles.phone}><a href="tel:+18665342639">866-534-ANEW</a></div>
				<button className={styles.toggleButton} aria-label="Toggle Navigation Menu" onClick={handleMenuToggle}>
					<div className={`${styles.first} ${btnImg}`}></div>
					<div className={`${styles.second} ${btnImg}`}></div>
					<div className={`${styles.trio} ${btnImg}`}></div>
				</button>
				<Link to={"/"} aria-label="Homepage" className={styles.imgLink}>
					<img
						src="/images/anew-logo.svg"
						alt="Anew Behavioral Health Logo"
						className={styles["anew-logo"]}
					/>
				</Link>
				<nav className={btnImg}>
					<ul className="row align-content-bottom">{linkItems}</ul>
				</nav>
				
			</div>
			<img src="/images/Tree.svg" alt="" className="tree-svg" />
		</header>
	);
}
