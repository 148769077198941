import { useState } from "react";
import formBuilder from "../../../components/Forms/formBuilder";
import styles from './forgot-password.module.css';
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingIcon from "../../../components/loading";


export default function ForgotPassword(props) {
	const [resetForm, setResetForm] = useState({
		email: formBuilder.configInput("input", "email", "Email", null, { required: true }),
	});
	const [error, setError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [emailSent, setEmailSent] = useState(false);

	const handleInputChange = (evt, id) => {
		const updatedForm = { ...resetForm };
		const updatedElement = { ...updatedForm[id] };
		updatedElement.value = evt.target.value;
		updatedForm[id] = updatedElement;
		formBuilder.checkValidity(updatedElement);
		setResetForm(updatedForm);
	};

	const handleFormSubmit = evt => {
		evt.preventDefault();

		let hasError = error ? false : false;
		for (let elt in resetForm) {
			formBuilder.checkValidity(resetForm[elt]);
			if (!resetForm[elt].valid) {
				hasError = true;
			}
		}

		if (hasError) {
			toast.error("you must properly fill out the form");
			setError(true);
			return;
		}

		const data = {
			email: resetForm.email.value,
		};

		setIsLoading(true);
		axios
			.post("/user/forgot-password", data)
			.then(resp => {
				if (resp.status === 200) {
					if (resp.data.success) {
						setEmailSent(true);
					}
					else if (resp.data.message) {
						toast.error(resp.data.message);
					}
					else {
						toast.error('A problem occurred. Please try again later.')
					}
					
				} else {
					toast.error("Invalid credentials.");
				}
			})
			.catch(err => {
				toast.error(err.message);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const form = formBuilder.buildForm(resetForm, handleInputChange);

	let resetFormDisplay = !isLoading ? (
		<form className={styles.loginForm} onSubmit={handleFormSubmit}>
			{form}
			<div className={styles.logButtonContainer}>
				<Link to="/">Cancel</Link>
				<button onClick={handleFormSubmit}>Send Link</button>
			</div>
		</form>
	) : (
		<LoadingIcon message="sending request" />
	);

	if (emailSent) {
		resetFormDisplay = (
			<p>If the supplied email matches an account in our system, a password reset email was sent. Please check your email to proceed.</p>
		);
	}

	return (
		<div className={styles.accountContainer}>
			<h1>Request Password Reset</h1>
			{resetFormDisplay}
		</div>
	);


}