import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { registerLicense } from "@syncfusion/ej2-base";
import axios from "axios";
import { getAuth } from "./services/authService";

registerLicense(`ORg4AjUWIQA/Gnt2VFhiQlJPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSH5RfkRhW3ZdcHNXTmM=`);

axios.defaults.baseURL = `${process.env.REACT_APP_DATABASE_URL}`;
axios.interceptors.request.use(req => {
	const auth = getAuth();
	if (auth && auth.token) {
		req.headers.Authorization = 'Bearer ' + auth.token;
	}
	return req;
})

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
