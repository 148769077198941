import { Suspense, lazy } from "react";
import LoadingIcon from "../../../components/loading";

const TeamLoading = lazy(() => import("./our-team"));

export default function OurTeamPage() {
	return (
		<Suspense fallback={<LoadingIcon />}>
			<TeamLoading />
		</Suspense>
	);
}
