import React from "react";
import styles from "./blog-card.module.css";
import { Link } from "react-router-dom";

export default function BlogCard(props) {
	const { primaryPhoto, title, body, postId } = props.post;

	let horizontalStyling = "";
	if (props.horizontal === "true") {
		horizontalStyling = styles["horizontalLayout"];
	}
	if (props.horizontal === "false") {
		horizontalStyling = styles["smallMobileHorizontal"];
	}

	let firstClass = "";
	if (props.first === "first") {
		firstClass = styles.visible;
		horizontalStyling = styles["smallMobileHorizontal"];
	} else if (props.first === "other") {
		firstClass = styles.invisible;
	}

	return (
		<Link to={`/articles/${postId}`}>
			<div className={`position-relative ${firstClass}`}>
				<div className={`${styles["blog-card-container"]} ${horizontalStyling}`} id="blogCard">
					<img
						src={`data:image/*;base64,${primaryPhoto}`}
						alt={`${title}`}
						className={styles["blog-card-image"]}
					/>
					<div>
						<h2 className={styles["blog-card-title"]}>{title}</h2>
						<div className={styles["blog-card-text"]} dangerouslySetInnerHTML={{ __html: body }}></div>
					</div>
				</div>
				<div className={styles["blog-shadow"]}></div>
			</div>
		</Link>
	);
}
