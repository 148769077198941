import React, { useEffect, useState } from "react";
import BlogCard from "../../../components/blog-card";
import styles from "./articles.module.css";
import axios from "axios";
import { toast } from "react-toastify";
import LoadingIcon from "../../../components/loading";
import ServerError from "../../../components/serverError";

export default function ArticlesPage() {
	const [blogs, setBlogs] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(false);

	useEffect(() => {
		axios
			.get("/BlogPost")
			.then(resp => {
				const blogs = [...resp.data];
				const filterResults = blogs.filter(blog => blog.published);
				const recentBlogs = filterResults.sort((a, b) => b.postedOn.localeCompare(a.postedOn));
				setBlogs(recentBlogs);
				setIsLoading(false);
			})
			.catch(err => {
				toast.error(err.message);
				setIsLoading(false);
				setError(true);
			});
	}, []);

	let blogPosts;

	if (blogs.length > 0) {
		blogPosts = (
			<div className={`row wrap ${styles.blogPosts}`} id="blogPosts">
				{blogs.map((blog, index) => {
					if (index === 0) {
						return <BlogCard post={blog} key={blog.postId} horizontal="false" />;
					} else {
						return <BlogCard post={blog} horizontal="true" key={blog.postId} />;
					}
				})}
			</div>
		);
	} else if (isLoading) {
		blogPosts = <LoadingIcon />;
	} else {
		blogPosts = <p> Sorry, there are no articles currently available</p>;
	}

	const page = error ? (
		<ServerError />
	) : (
		<section id="articlesPage">
			<h1>Articles</h1>

			{blogPosts}
		</section>
	);

	return page;
}
