import { Link } from "react-router-dom";
import styles from "../pages/not-found.module.css";

export default function serverError(props) {
	const hideText = props.admin === "true" ? styles.hide : "";
	return (
		<div className={styles["notFound-container"]}>
			<h1>Oops...</h1>
			<p>We experienced an error with your request.</p>
			<img src="/images/Img_404Acorn.svg" alt="Acorn with missing cupule"></img>
			<p>Please try again later.</p>
			<p className={hideText}>
				In the meantime, head back to the <Link to={"/"}>homepage</Link> or contact us{" "}
				<Link to={"/contact"}>here</Link>
			</p>
		</div>
	);
}
