import { useState, useContext, useRef } from "react";
import formBuilder from "../Forms/formBuilder";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./login-form.module.css";
import LoadingIcon from "../loading";
import { setAuth } from "../../services/authService";
import AuthContext from "../../context/auth-context";
import ReCAPTCHA from 'react-google-recaptcha';

export default function LoginForm(props) {
	const [accountForm, setAccountForm] = useState({
		username: formBuilder.configInput("input", "text", "Username", null, { required: true }),
		password: formBuilder.configInput("input", "password", "Password", null, { required: true }),
	});
	const [error, setError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const authContext = useContext(AuthContext);
	const captchaRef = useRef(null);

	const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;


	const handleInputChange = (evt, id) => {
		const updatedForm = { ...accountForm };
		const updatedElement = { ...updatedForm[id] };
		updatedElement.value = evt.target.value;
		updatedForm[id] = updatedElement;
		formBuilder.checkValidity(updatedElement);
		setAccountForm(updatedForm);
	};
	
	const handleFormSubmit = evt => {
		evt.preventDefault();

		let hasError = error ? false : false;
		for (let elt in accountForm) {
			formBuilder.checkValidity(accountForm[elt]);
			if (!accountForm[elt].valid) {
				hasError = true;
			}
		}

		if (hasError) {
			toast.error("you must properly fill out the form");
			setError(true);
			return;
		}

		let token = captchaRef.current.getValue();
		// captchaRef.current.reset();

		if (token) {
			const data = {
				username: accountForm.username.value,
				password: accountForm.password.value,
				captchaToken: token,
			};
	
			setIsLoading(true);
			axios
				.post("/user/bearertoken", data)
				.then(resp => {
					if (resp.status === 200) {
						setAuth(resp.data.token, resp.data.expiration);
						authContext.setAuthInfo({
							token: resp.data.token,
							expiration: resp.data.expiration
						});
						
					} else {
						toast.error("Invalid credentials.");
					}
				})
				.catch(err => {
					toast.error(err.message);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
		else {
			toast.error('You must fill out the captcha.')
		}

	};

	const form = formBuilder.buildForm(accountForm, handleInputChange);

	const loginFormDisplay = !isLoading ? (
		<form className={styles.loginForm} onSubmit={handleFormSubmit}>
			{form}
			<div>
				<ReCAPTCHA
					className="recaptcha"
					sitekey={SITE_KEY}
					ref={captchaRef}
					/>
			</div>
			<div className={styles.logButtonContainer}>
				<Link to="/">Cancel</Link>
				<button onClick={handleFormSubmit}>Log In</button>
			</div>
			<div className={styles.logButtonContainer}>
				<Link to="/admin/forgot-password" className={styles.plain}>forgot password?</Link>
			</div>
		</form>
	) : (
		<LoadingIcon message="logging in" />
	);

	return (
		<div className={styles.accountContainer}>
			<h1>Log In</h1>
			{loginFormDisplay}
			
		</div>
	);
}
