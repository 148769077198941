import React from "react";
import { Link } from "react-router-dom";
import styles from "./footer.module.css";

export default function AnewFooter() {
	return (
		<>
		<footer className="container-width">
			<div className={styles.icons}>
				<div className={styles.first}>
					<Link to="/">
						<img src="/images/Icon_Forms.svg" alt="a form" />
						Forms
					</Link>
				</div>
				<div className={styles.second}>
					<Link to="/telehealth">
						<img src="/images/Icon_Telehealth.svg" alt="a mobile device" />
						Telehealth
					</Link>
				</div>
				<div className={styles.third}>
					<Link to="/careers">
						<img src="/images/Icon_JoinTheTeam.svg" alt="a briefcase" />
						Join the Team
					</Link>
				</div>
				<div className={styles.fourth}>
					<Link to="/articles">
						<img src="/images/Icon_Articles.svg" alt="a blog article" />
						Articles
					</Link>
				</div>
				<div className={styles.logo}>
					<img src="/images/anew-logo.svg" alt="Anew Behavioral Health Logo" className={styles.anewLogo} />
				</div>
			</div>
		</footer>
		<div className={styles.subfooter}>
			<div className={styles.socialmedia}>
				<a aria-label="Link to Facebook page" href="https://www.facebook.com/ANEWbh/" target="_blank" rel="noreferrer">
					<img src="/images/IconFacebook.svg" alt="Facebook icon" />
				</a>
				<a aria-label="Link to Instagram page" href="https://instagram.com/anew_bh?igshid=NTc4MTIwNjQ2YQ==" target="_blank" rel="noreferrer">
					<img src="/images/IconInstagram.svg" alt="Instagram icon" />
				</a>
			</div>
			<div className={styles.copy}>&copy; Anew Behavioral Health 2023</div>
			<div className={styles.designed}>Designed by <a href="https://logicalimagination.com" rel="noreferrer" target="_blank">Logical Imagination</a></div>
		</div>
		</>
	);
}
