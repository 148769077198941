import { useState } from "react";
import formBuilder from "../../../components/Forms/formBuilder";
import axios from "axios";
import styles from "./testimonial.module.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingIcon from "../../../components/loading";

export default function AddTestimonial() {
	const [itemForm, setItemForm] = useState({
		clientName: formBuilder.configInput("input", "text", "Name", null, { required: true, minLength: 2 }),
		testimonialText: formBuilder.configInput("textarea", null, "Testimonial", null, { required: true }),
		active: formBuilder.configInput('input', 'checkbox', 'Active', null, {}),
	});
	const navigate = useNavigate();
	const [error, setError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const handleInputChange = (evt, id) => {
		const updatedForm = { ...itemForm };
		const updatedElement = { ...updatedForm[id] };
		if (updatedElement.attrs.type && updatedElement.attrs.type === 'checkbox') {
			updatedElement.value = evt.target.checked;
		}
		else {
			updatedElement.value = evt.target.value;
		}
		updatedForm[id] = updatedElement;
		formBuilder.checkValidity(updatedElement);
		setItemForm(updatedForm);
	};

	const handleFormSubmit = evt => {
		evt.preventDefault();

		let hasError = error ? false : false;
		for (let elt in itemForm) {
			formBuilder.checkValidity(itemForm[elt]);
			if (!itemForm[elt].valid) {
				hasError = true;
			}
		}

		if (hasError) {
			toast.error("you must properly fill out the form");
			setError(true);
			return;
		}

		setIsLoading(true);

		const data = {
			clientName: itemForm.clientName.value,
			testimonialText: itemForm.testimonialText.value,
			active: itemForm.active.value,
		};

		axios
			.post("/testimonial", data)
			.then(resp => {
				if (resp.status === 201) {
					toast.success(`Testimonial was added`);
					navigate("/admin/testimonials");
				} else {
					toast.error("There was an error with your request");
				}
				setIsLoading(false);
			})
			.catch(err => {
				toast.error(err);
				setIsLoading(false);
			});
	};

	const form = formBuilder.buildForm(itemForm, handleInputChange);

	const formContent = !isLoading ? (
		<form className={styles.itemForm}>
			{form}
			<div className={styles.btnContainer}>
				<Link to={"/admin/testimonials"}>Cancel</Link>
				<button onClick={handleFormSubmit}>Add Testimonial</button>
			</div>
		</form>
	) : (
		<LoadingIcon />
	);

	return (
		<>
			<h1>Add Testimonial</h1>
			{formContent}
		</>
	);
}
