import { getAuth } from "../services/authService";

export const toolbarSettings = {
	items: [
		"Bold",
		"Italic",
		"Underline",
		"StrikeThrough",
		"FontName",
		"FontSize",
		"FontColor",
		"LowerCase",
		"UpperCase",
		"|",
		"Formats",
		"Alignments",
		"OrderedList",
		"UnorderedList",
		"Outdent",
		"Indent",
		"|",
		"CreateLink",
		"Image",
		"|",
		"ClearFormat",
		"Print",
		"SourceCode",
		"|",
		"Undo",
		"Redo",
	],
};

export const fontFamily = {
	default: "Montserrat",
	items: [
		{ text: "Architects Daughter", value: "Architects Daughter", command: "Font", subCommand: "FontName" },
		{ text: "Montserrat", value: "Montserrat", command: "Font", subCommand: "FontName" },
	],
};

export const fontColor = {
	columns: 2,
	colorCode: { Custom: ["#444444", "#4A8235", "#ADE299", "#F9D976", "#F9921D", "#033F63", "#FEFEFE"] },
	modeSwitcher: true,
	default: "#444444",
};

export const fontSize = {
	default: "16",
	items: [
		{ text: "16", value: "1rem" },
		{ text: "20", value: "1.25rem" },
		{ text: "24", value: "1.5rem" },
		{ text: "28", value: "1.75rem" },
		{ text: "32", value: "2rem" },
		{ text: "36", value: "2.25rem" },
	],
};

export const quickToolbarSettings = {
	image: [
		"Replace",
		"Align",
		"Caption",
		"Remove",
		"InsertLink",
		"OpenImageLink",
		"-",
		"EditImageLink",
		"RemoveImageLink",
		"Display",
		"AltText",
		"Dimension",
	],
};

export const insertImageSettings = {
	saveUrl: "https://api.anewbh.com/api/image",
	path: "",
};

export const handleImageUploadSuccess = args => {
	if (args.e.currentTarget.response !== null) {
		args.file.name = args.e.currentTarget.response;
		let filename = document.querySelectorAll(".e-file-name")[0];
		filename.innerHTML = args.file.name.replace(document.querySelectorAll(".e-file-name")[0].innerHTML, "");
		filename.title = args.file.name;
	}
};


export const imageUploadingHandler = args => {
	const req = args.currentRequest;
	const auth = getAuth();
	req.setRequestHeader('Authorization', 'Bearer ' + auth.token);
}
