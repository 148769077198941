import { useEffect, useState } from "react";
import formBuilder from "../../../components/Forms/formBuilder";
import axios from "axios";
import styles from "./testimonial.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ServerError from "../../../components/serverError";
import LoadingIcon from "../../../components/loading";

export default function EditTestimonial() {
	const [itemForm, setItemForm] = useState({
		clientName: formBuilder.configInput("input", "text", "Name", null, { required: true, minLength: 2 }),
		testimonialText: formBuilder.configInput("textarea", null, "Testimonial", null, { required: true }),
		active: formBuilder.configInput('input', 'checkbox', 'Active', null, {}),
	});
	const navigate = useNavigate();
	const { id } = useParams();
	const [error, setError] = useState(false);
	const [item, setItem] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [errorLoading, setErrorLoading] = useState(false);


	useEffect(() => {
		axios
			.get(`/testimonial/${id}`)
			.then(resp => {
				setItem(resp.data);
				setItemForm({
					clientName: formBuilder.configInput("input", "text", "Name", null, { required: true }, resp.data.clientName),
					testimonialText: formBuilder.configInput("textarea", null, "Testimonial", null, { required: true }, resp.data.testimonialText),
					active: formBuilder.configInput('input', 'checkbox', 'Active', null, {}, resp.data.active),
				});
			})
			.catch(err => {
				toast.error(err.message);
				setErrorLoading(true);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [id]);

	const handleInputChange = (evt, id) => {
		const updatedForm = { ...itemForm };
		const updatedElement = { ...updatedForm[id] };
		if (updatedElement.attrs.type && updatedElement.attrs.type === 'checkbox') {
			updatedElement.value = evt.target.checked;
		}
		else {
			updatedElement.value = evt.target.value;
		}
		updatedForm[id] = updatedElement;
		formBuilder.checkValidity(updatedElement);
		setItemForm(updatedForm);
	};

	const handleFormSubmit = evt => {
		evt.preventDefault();

		let hasError = error ? false : false;
		for (let elt in itemForm) {
			formBuilder.checkValidity(itemForm[elt]);
			if (!itemForm[elt].valid) {
				hasError = true;
			}
		}

		if (hasError) {
			toast.error("you must properly fill out the form");
			setError(true);
			return;
		}

		setIsLoading(true);

		const data = {
			clientName: itemForm.clientName.value,
			testimonialText: itemForm.testimonialText.value,
			active: itemForm.active.value,
		};

		axios
			.put(`testimonial/${id}`, data)
			.then(resp => {
				if (resp.status === 200) {
					toast.success("Testimonial Updated");
					navigate("/admin/testimonials");
				} else {
					toast.error("There was an error with your request");
					setIsLoading(false);
				}
			})
			.catch(err => {
				setIsLoading(false);
				toast.error(err.message);
			});
	};

	const form = formBuilder.buildForm(itemForm, handleInputChange);

	let formContent;

	if (isLoading) {
		formContent = <LoadingIcon />;
	} else if (errorLoading) {
		formContent = <ServerError />;
	} else {
		formContent = (
			<form className={styles.itemForm}>
				{form}
				<div className={styles.btnContainer}>
					<Link to={"/admin/testimonials"}>Cancel</Link>
					<button onClick={handleFormSubmit}>Update Testimonial</button>
				</div>
			</form>
		);
	}

	return (
		<>
			<h1>Edit Testimonial</h1>
			{formContent}
		</>
	);
}
