import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap, faNewspaper, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import styles from "./adminHome.module.css";

export default function AdminHome() {
	return (
		<div className={styles.adminHome}>
			<h1>
				<span className="underline">Welcome</span>
			</h1>
			<nav>
				<div>
					<p>
						<FontAwesomeIcon icon={faNewspaper} />
						Articles
					</p>
					<ul>
						<li>
							<Link to="/admin/articles">Articles List</Link>
						</li>
						<li>
							<Link to="/admin/articles/add">Add Article</Link>
						</li>
					</ul>
				</div>
				<div>
					<p>
						<FontAwesomeIcon icon={faUserGroup} /> Providers
					</p>
					<ul>
						<li>
							<Link to="/admin/providers">Providers List</Link>
						</li>
						<li>
							<Link to="/admin/providers/add">Add a Provider</Link>
						</li>
					</ul>
				</div>

				<div>
					<p>
						<FontAwesomeIcon icon={faMap} /> Locations
					</p>
					<ul>
						<li>
							<Link to="/admin/locations">Locations List</Link>
						</li>
						<li>
							<Link to="/admin/locations/add">Add Location</Link>
						</li>
					</ul>
				</div>

				<div>
					<p>
						<FontAwesomeIcon icon={faMap} /> Testimonials
					</p>
					<ul>
						<li>
							<Link to="/admin/testimonials">Testimonials List</Link>
						</li>
						<li>
							<Link to="/admin/testimonials/add">Add Testimonial</Link>
						</li>
					</ul>
				</div>

			</nav>
		</div>
	);
}
