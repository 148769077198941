import { useContext } from 'react';
import LoginForm from '../account/login-form';
import AuthContext from '../../context/auth-context';

const RouteProtector = (props) => {

	const authContext = useContext(AuthContext);

	let content = <LoginForm></LoginForm>
	if (authContext.isLoggedIn()) {
		content = props.children;
	}

	return (<>
		{content}
	</>)

};

export default RouteProtector;
