import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import formBuilder from "../../../components/Forms/formBuilder";
import styles from './reset-password.module.css';
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingIcon from "../../../components/loading";

export default function ResetPassword(props) {
	const [resetForm, setResetForm] = useState({
		newPassword: formBuilder.configInput("input", "password", "New Password", null, { required: true }),
		confirmPassword: formBuilder.configInput("input", "password", "Confirm Password", null, { required: true, sameAs: 'newPassword' }),
	});
	const [error, setError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [passwordWasReset, setPasswordWasReset] = useState(false);
	const [resetData, setResetData] = useState({
		email: '',
		token: '',
		valid: false
	});
	const [searchParams] = useSearchParams();

	useEffect(() => {
		const email = searchParams.get('email');
		const token = searchParams.get('token');

		setResetData({
			email: email,
			token: token,
			valid: !!(email && token)
		})

	}, [searchParams]);

	const handleInputChange = (evt, id) => {
		const updatedForm = { ...resetForm };
		const updatedElement = { ...updatedForm[id] };
		updatedElement.value = evt.target.value;
		updatedForm[id] = updatedElement;
		formBuilder.checkValidity(updatedElement, updatedForm);
		setResetForm(updatedForm);
	};

	const handleFormSubmit = evt => {
		evt.preventDefault();

		let hasError = error ? false : false;
		for (let elt in resetForm) {
			formBuilder.checkValidity(resetForm[elt], resetForm);
			if (!resetForm[elt].valid) {
				hasError = true;
			}
		}

		if (hasError) {
			toast.error("you must properly fill out the form");
			setError(true);
			return;
		}

		const data = {
			password: resetForm.newPassword.value,
			confirmPassword: resetForm.confirmPassword.value,
			email: resetData.email,
			token: resetData.token
		};

		setIsLoading(true);
		axios
			.post("/user/reset-password", data)
			.then(resp => {
				if (resp.status === 200) {
					
					if (resp.data.success) {
						setPasswordWasReset(true);
					}
					else if (resp.data.message) {
						toast.error(resp.data.message);
					}
					else {
						toast.error('A problem occurred. Please try again later.')
					}
					
				} else {
					toast.error("Password reset request may have expired. Please try sending the request again.");
				}
			})
			.catch(err => {
				toast.error(err.message);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const form = formBuilder.buildForm(resetForm, handleInputChange);

	let resetFormDisplay;

	if (passwordWasReset) {
		resetFormDisplay = (
			<p className={styles.loginForm}>Your password was reset. Please <Link to="/admin/account">login</Link> to continue.</p>
		);
	}
	else if (resetData.valid) {
		resetFormDisplay = !isLoading ? (
			<form className={styles.loginForm} onSubmit={handleFormSubmit}>
				{form}
				<div className={styles.logButtonContainer}>
					<Link to="/">Cancel</Link>
					<button onClick={handleFormSubmit}>Reset Password</button>
				</div>
			</form>
		) : (
			<LoadingIcon message="sending request" />
		);
	}
	else {
		resetFormDisplay = (<p>It appears that you have reached this page in error. No existing password reset request detected.</p>);
	}

	return (
		<div className={styles.accountContainer}>
			<h1>Reset Password</h1>
			{resetFormDisplay}
		</div>
	);

}
