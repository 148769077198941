import { useEffect, useState } from "react";
import formBuilder from "../../../components/Forms/formBuilder";
import axios from "axios";
import styles from "./add-location.module.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AddState from "../../../components/adminComponents/add-state";
import Backdrop from "../../../components/Modal/backdrop";
import LoadingIcon from "../../../components/loading";

export default function AddLocation() {
	const [locationForm, setLocationForm] = useState({
		name: formBuilder.configInput("input", "text", "Name", null, { required: true, minLength: 2 }),
		street: formBuilder.configInput("input", "text", "Street", null, { required: true }),
		suite: formBuilder.configInput("input", "text", "Suite", null, {}),
		city: formBuilder.configInput("input", "text", "City", null, {}, ""),
		stateProvinceAbbreviation: formBuilder.configInput(
			"select",
			"",
			"State",
			null,
			{
				required: true,
			},
			"",
			{ states: [] }
		),
		postalCode: formBuilder.configInput("input", "text", "Postal Code", null, { reqLength: 5 }, ""),
		phone: formBuilder.configInput("input", "text", "Phone Number", null, { required: true }, ""),
		fax: formBuilder.configInput("input", "text", "Fax", null, {}, ""),
	});
	const navigate = useNavigate();
	const [states, setStates] = useState([]);
	const [error, setError] = useState(false);
	const [displayStateModal, setDisplayStateModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		axios
			.get("/StateProvince")
			.then(resp => {
				const states = resp.data;
				const sortedStates = states.sort((a, b) => a.abbreviation.localeCompare(b.abbreviation));
				setStates(sortedStates);
				setIsLoading(false);
			})
			.catch(err => {
				toast.error(err.message);
				setIsLoading(false);
			});
	}, []);

	useEffect(() => {
		if (states.length > 0) {
			setLocationForm({
				name: formBuilder.configInput("input", "text", "Name", null, { required: true }, ""),
				street: formBuilder.configInput("input", "text", "Street", null, { required: true }, ""),
				suite: formBuilder.configInput("input", "text", "Suite", null, {}, ""),
				city: formBuilder.configInput("input", "text", "City", null, {}, ""),
				stateProvinceAbbreviation: formBuilder.configInput(
					"select",
					"",
					"State",
					null,
					{
						required: true,
					},
					"",
					{ states, handleAddStateModal }
				),
				postalCode: formBuilder.configInput("input", "text", "Postal Code", null, { reqLength: 5 }, ""),
				phone: formBuilder.configInput("input", "text", "Phone Number", null, { required: true }, ""),
				fax: formBuilder.configInput("input", "text", "Fax", null, {}, ""),
			});
		}
	}, [states]);

	const handleAddStateModal = evt => {
		evt.preventDefault();
		setDisplayStateModal(true);
	};

	const handleCloseStateModal = evt => {
		evt.preventDefault();
		setDisplayStateModal(false);
	};

	const successfulAddedState = data => {
		const addStates = [...states];
		addStates.push(data);
		const sortedStates = addStates.sort((a, b) => a.abbreviation.localeCompare(b.abbreviation));
		setStates(sortedStates);
		setDisplayStateModal(false);
	};

	const handleInputChange = (evt, id) => {
		const updatedForm = { ...locationForm };
		const updatedElement = { ...updatedForm[id] };
		updatedElement.value = evt.target.value;
		updatedForm[id] = updatedElement;
		formBuilder.checkValidity(updatedElement);
		setLocationForm(updatedForm);
	};

	const handleFormSubmit = evt => {
		evt.preventDefault();

		let hasError = error ? false : false;
		for (let elt in locationForm) {
			formBuilder.checkValidity(locationForm[elt]);
			if (!locationForm[elt].valid) {
				hasError = true;
			}
		}

		if (hasError) {
			toast.error("you must properly fill out the form");
			setError(true);
			return;
		}

		setIsLoading(true);

		const data = {
			name: locationForm.name.value,
			street: locationForm.street.value,
			suite: locationForm.suite.value,
			city: locationForm.city.value,
			postalCode: locationForm.postalCode.value,
			phone: locationForm.phone.value,
			fax: locationForm.fax.value,
			stateProvinceAbbreviation: locationForm.stateProvinceAbbreviation.value,
		};

		const findState = states.find(state => state.abbreviation === data.stateProvinceAbbreviation);

		data.stateProvinceId = findState.stateProvinceId;
		data.stateProvinceName = findState.name;

		axios
			.post("/Location", data)
			.then(resp => {
				if (resp.status === 201) {
					toast.success(`${data.name} was added`);
					navigate("/admin/locations");
				} else {
					toast.error("There was an error with your request");
				}
				setIsLoading(false);
			})
			.catch(err => {
				toast.error(err);
				setIsLoading(false);
			});
	};

	const form = formBuilder.buildForm(locationForm, handleInputChange);

	const displayLocationsForm = !isLoading ? (
		<form className={styles.locationForm}>
			{form}
			<div className={styles.btnContainer}>
				<Link to={"/admin/locations"}>Cancel</Link>
				<button onClick={handleFormSubmit}>Add Location</button>
			</div>
		</form>
	) : (
		<LoadingIcon />
	);

	return (
		<>
			{displayStateModal && <Backdrop show={displayStateModal} clicked={handleCloseStateModal} />}
			<h1>Add Location</h1>
			{displayLocationsForm}
			{displayStateModal && <AddState close={handleCloseStateModal} success={successfulAddedState} />}
		</>
	);
}
