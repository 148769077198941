import styles from "./loading.module.css";

export default function LoadingIcon(props) {
	let { message } = props;
	let text = "Loading...";
	if (message === "sending") text = "Sending Information...";
	if (message === "saving") text = "Saving...";
	if (message === "publish") text = "Publishing...";

	return (
		<div className={styles.loadingIcon}>
			<h2>{text}</h2>
			<img src="/images/LoadingTreeGrowing.gif" alt="Loading Icon of a leaf growing"></img>
		</div>
	);
}
