
const AUTH_KEY = 'REACT_AUTH_KEY';
const zeroDate = Date.parse('01 Jan 1970 00:00:00 GMT');
export const defaultAuthValue = {
	token: '',
	expiration: zeroDate
};


export const setAuth = (token, dateString) => {

	let dateValue = zeroDate;
	try {
		dateValue = Date.parse(dateString);
	}
	catch {}

	const authData = {
		token: token,
		expiration: dateValue
	};

	window.sessionStorage.setItem(AUTH_KEY, JSON.stringify(authData));
};

export const getAuth = () => {
	let value;
	const test = window.sessionStorage.getItem(AUTH_KEY);
	if (test) {
		try {
			value = JSON.parse(test);
		}
		catch {
			value = defaultAuthValue;
		}
	}
	else {
		value = defaultAuthValue;
	}
	return value;
};

export const clearAuth = () => {
	window.sessionStorage.removeItem(AUTH_KEY);
};

export const isLoggedIn = () => {
	const auth = getAuth();
	if (auth.token === '') return false;	// we have no token

	const now = new Date();
	if (auth.expiration < now) return false;	// token is expired

	return true;
}



// monkey-patch fetch to create an interceptor
// (necessary for Syncfusion rich text editor to upload pictures 
//  b/c the API requires auth and Syncfusion rte does not use our Axios)
// const { fetch: originalFetch } = window;

// window.fetch = async (...args) => {
// 	console.log('interceptor running');

// 	let [resource, options] = args;
// 	if (resource.includes('/api/image') && options.method.toLowerCase() === 'post') {
// 		console.log('uploading image');

// 		const auth = getAuth();
// 		if (auth.token) {
// 			console.log('have token');

// 			if (!options.headers['Authorization']) {
// 				console.log('adding token');
// 				options.headers['Authorization'] = 'Bearer ' + auth.token;
// 			}
// 		}
// 	}
// 	const response = await originalFetch(resource, options);
// 	return response;
// };

