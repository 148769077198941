import React from "react";
import styles from "./scrollbar.module.css";

export default function ScrollBar(props) {
	const locations = props.locations;
	const handleSelectedLocation = props.selectLocation;

	if (!locations.length) {
		return (<div className={styles.scrollContainer}><h2>no locations match your search</h2></div>);
	}

	const listLocations = locations.map((location, idx) => {
		const { locationId, name, street, suite, city, stateProvinceAbbreviation, postalCode, phone } = location;

		const address = suite ? `${street}, ${suite}` : street;

		return (
			<li key={locationId}>
				<button onClick={() => handleSelectedLocation(location)}>
					<h2>{name}</h2>
				</button>

				<p>{address}</p>
				<p>
					{city}, {stateProvinceAbbreviation}, {postalCode}
				</p>
				<a href={`tel:+1${phone}`}>{phone}</a>
			</li>
		);
	});
	
	
	return <ol className={styles.scrollContainer}>{listLocations}</ol>;
}
