const howHelp = [
	{
		id: 1,
		topic: "Assessments & Access",
		shortUrl: "assessments",
		color: "blue",
		subTopic: [
			{
				id: 3,
				title: "TeleHealth",
				content: `Anew is proud to be able to offer services in a variety of formats to best meet your needs. 
                <br><br>
                If you are near one of our physical locations, we would love to have you come in for in person services. If you cannot make it to one of our offices, or just prefer to utilize telehealth services, those are also available for all of our services and all of our clients. 
                <br><br>
                Anew also offers a hybrid model that utilizes our or your technology for some sessions and services, and are offered in person for others.`,

				videoSrc: "\\videos\\HowWeCanHelpVideos\\Cropped_TeleHealth.mp4",
			},
		],
	},
	{
		id: 2,
		topic: "Case Management",
		shortUrl: "case-management",
		color: "dark-green",
		subTopic: [
			{
				id: 1,
				title: "Case Managers",
				content: `All case management services at Anew are provided by qualified behavioral health specialists. These are individuals who have been specifically trained to assist you in navigating the day-to-day challenges associated with mental health, substance use, or dual diagnosis recovery.
                <br><br>
                If your therapist and your psychiatrist diagnose you and lay the foundation for your treatment, our qualified behavioral health specialists will be there to help you learn the skills necessary to execute on those individualized plans for your success.`,
				videoSrc: "\\videos\\HowWeCanHelpVideos\\Cropped_CaseManagers.mp4",
			},
			{
				id: 2,
				title: "Case Coordination",
				content: `Our qualified behavioral health specialists will work directly with you to monitor your ongoing progress, facilitate the development of daily living skills and coping mechanisms, and provide education and interventions to address symptoms, behaviors, and thought processes that are current barriers to your success.
                <br><br>
                Whether it's assisting with transportation issues. Connecting you to other resources or practicing new coping skills, case management serves an essential role in turning plans into action and turning dreams into reality.`,
				videoSrc: "\\videos\\HowWeCanHelpVideos\\Cropped_CaseCoordination.mp4",
			},
		],
	},
	{
		id: 3,
		topic: "Integrated & Individualized Services",
		shortUrl: "integrated-individualized-services",
		color: "orange",
		subTopic: [
			{
				id: 1,
				title: "Customized Care",
				content: `The foundational belief of integrated behavioral health systems like Anew is that physical, behavioral, and social health are inextricably intertwined.
                <br><br>

                Fragmented, or disconnected systems of care create barriers to achieving optimal whole person health. Integrated services like combining psychiatric, nursing, and therapy along with case management is a solution to the problems that receiving multiple services from multiple organizations can cause. 
                <br><br>

                Improved accuracy of screening and diagnosis, improved follow through with care protocols, reduced emergency room visits, and crisis events. 
                <br><br>

                Most importantly, clients prefer integrated care with over 70% of people reporting that they experience better success and that they preferred that approach.
                <br><br>

                However, patient health outcomes also improve when providers individualize care and take specific real life circumstances and client choices into account. So the best way to think of Anew is like a buffet. 
                <br><br>

                We have many integrated service options that are available to you and we strongly encourage that each client considers all of the services that could potentially benefit them. Ultimately though, it is each client's choice about which services they do and do not want.`,
				videoSrc: "\\videos\\HowWeCanHelpVideos\\Cropped_CustomizedCare.mp4",
			},
		],
	},
	{
		id: 4,
		topic: "Psychiatric & Nursing",
		shortUrl: "psychiatric-services",
		color: "gold",
		subTopic: [
			{
				id: 1,
				title: "Our Psychiatrists",
				content: `At Anew, our psychiatrists and prescribers are focused on the diagnosis, treatment, and prevention of mental, emotional and behavioral disorders. 
                <br><br>
                These are medical professionals who specialize in mental health and substance use disorders. All of our providers are qualified to assess both the mental and physical aspects of psychological problems.
                <br><br>

                People seek psychiatric help for many reasons. The problems can be sudden and immediate, such as a panic attack, frightening hallucinations, thoughts of self harm, or even hearing voices.
                <br><br>

                Or, they may be more long term, such as feelings of sadness, hopelessness or anxiousness that never seem to lift or cause problems with day-to-day functioning. 
                <br><br>

                It can cause everyday life to feel distorted or out of control. So at Anew, our psychiatrists are going to focus on providing accurate diagnosis, constructing effective plans for your ongoing care, and monitoring your physical and emotional symptoms to ensure your health and stability.`,
				videoSrc: "\\videos\\HowWeCanHelpVideos\\Cropped_OurPsychiatrists.mp4",
			},
			{
				id: 2,
				title: "Medication",
				content: `At Anew our psychiatrists and prescribers may use a variety of treatments, including various forms of psychotherapy, medications, psychosocial interventions, and other treatments depending on the specific individual needs of each patient.
                <br><br>

                In some cases, this may mean that you will be prescribed psychotropic medications. Most medications are used by psychiatrists in much the same way that medications are used to treat high blood pressure or diabetes. 
                <br><br>

                After completing a thorough evaluation, psychiatrists can prescribe medications to help treat mental disorders. 
                <br><br>

                These medications may beneficially modulate chemical signaling and communication within the brain, which may reduce some symptoms of your disorder. 
                <br><br>

                Patients receiving medication treatment will need to meet with Anew medical providers periodically to monitor the effectiveness of the medication and monitor any potential side effects.`,
				videoSrc: "\\videos\\HowWeCanHelpVideos\\Cropped_Medication.mp4",
			},
			{
				id: 3,
				title: "Our Nurses",
				content: `At Anew our behavioral health nurses bring a wide range of nursing, psychosocial, and neurobiological expertise to the work they love. <br><br> Our nurses promote well-being through prevention and education, in addition to the assessment, care and treatment of mental health, substance use and dual diagnosis challenges.`,
				videoSrc: "\\videos\\HowWeCanHelpVideos\\Cropped_OurNurses.mp4",
			},
			{
				id: 4,
				title: "Nursing Support",
				content: `Our nursing team will partner with you at every opportunity to help you achieve your wellness goals.
                <br><br>
                By offering services such as assessment, screening and triage to assisting you in managing ongoing health conditions to partnering with other providers to ensure that you receive fully coordinated care.
                <br><br>
                Nurses at a new provide invaluable support that directly improves patient outcomes.`,
				videoSrc: "\\videos\\HowWeCanHelpVideos\\Cropped_NursingSupport.mp4",
			},
		],
	},
	{
		id: 5,
		topic: "Recovery Support",
		shortUrl: "recovery-support",
		color: "light-green",
		subTopic: [
			{
				id: 1,
				title: "Medically Assisted Treatment (MAT)",
				content: `Medically assisted or medication assisted treatment, often known as MAT, is the use of medications in combination with counseling and other therapeutic techniques to provide a whole patient approach to the treatment of substance use disorders. 
                <br><br>
                Medications can relieve the withdrawal symptoms and psychological cravings that can cause chemical imbalances within your body. 
                <br><br>

                Research has shown that when provided at the proper dosage with appropriate monitoring, medications used in MAT such as Vivitrol, Sublocade, Suboxone and others are safe, effective, and can directly improve your ability to maintain your sobriety long term.`,
				videoSrc: "\\videos\\HowWeCanHelpVideos\\Cropped_MedicallyAssistedTreatment.mp4",
			},
		],
	},
	{
		id: 6,
		topic: "Therapy",
		shortUrl: "therapy",
		color: "blue",
		subTopic: [
			{
				id: 1,
				title: "Our Therapists",
				content: `Anew therapists are trained professionals who assist people with various mental health, substance use and dual diagnosis challenges such as stress, depression, anxiety, insomnia, addiction, bipolar disorders, negative behavior patterns, schizophrenia and other limiting emotional states. 
                <br><br>
                Psychotherapy is also called talk therapy and has been proven to help individuals understand themselves on a deeper. Hence equipping them to better face new challenges both in the present and future. 
                <br><br>
                Anew therapists are always non judgmental and compassionate, and they utilize evidence based solutions and strengths focused approaches that are rooted in positive psychological principles.`,
				videoSrc: "\\videos\\HowWeCanHelpVideos\\Cropped_OurTherapists.mp4",
			},
			{
				id: 2,
				title: "Types of Therapy",
				content: `As with all Anew services, approaches to therapy are individualized and integrated. 
                <br><br>

                This means that your therapy can be conducted with you as an individual, couples, families, or in group settings. 
                <br><br>

                It really depends on what will best meet your individual needs. 
                <br><br>

                Regardless of the structure, all therapy services are most effective when they are combined with other supportive services such as psychiatric, nursing, and case management services.`,
				videoSrc: "\\videos\\HowWeCanHelpVideos\\Cropped_TypesOfTherapy.mp4",
			},
		],
	},
];

export default howHelp;
