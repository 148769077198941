import styles from "../articles/articlesDashboard.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCirclePlus,
	faMagnifyingGlass,
	faEdit,
	faTrashCan,
	faArrowUpAZ,
	faArrowDownZA,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Backdrop from "../../../components/Modal/backdrop";
import DeleteModal from "../../../components/adminComponents/delete-modal";
import LoadingIcon from "../../../components/loading";

const tableHeadings = [
	{ topic: "Name", id: "clientName", class: "" },
	{ topic: 'Active', id: 'active', class: ''},
	{ topic: "Testimonial", id: "testimonialText", class: "" },
];

export default function TestimonialsDashboard() {
	const [items, setItems] = useState([]);
	const [displayItems, setDisplayItems] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const inputRef = useRef(null);
	const [filterTopic, setFilterTopic] = useState("clientName");
	const [selectedTopic, setSelectedTopic] = useState("clientName");
	const [isLoading, setIsLoading] = useState(true);
	const [displayModal, setDisplayModal] = useState(false);
	const [deleteItem, setDeleteItem] = useState(null);

	useEffect(() => {
		axios
			.get("/testimonial")
			.then(resp => {
				setItems(resp.data);

				const sortName = resp.data.sort((a, b) => a.clientName.localeCompare(b.clientName));
				// const sortTestimonial = sortName.sort((a, b) =>
				// 	a.testimonialText.localeCompare(b.testimonialText)
				// );
				setDisplayItems(sortName);
				setIsLoading(false);
			})
			.catch(err => {
				setIsLoading(false);
				toast.error(err.message);
			});
	}, []);

	useEffect(() => {
		const timerId = setTimeout(() => {
			if (inputRef.current.value === searchTerm) {
				const display = items.filter(
					item =>
						searchTerm === "" ||
						item.clientName.toLowerCase().includes(searchTerm) ||
						item.testimonialText.toLowerCase().includes(searchTerm)
				);

				setDisplayItems(display);
			}
		}, 500);

		return () => clearTimeout(timerId);
	}, [searchTerm, items]);

	const handleInputChange = evt => {
		setSearchTerm(evt.target.value.toLowerCase());
	};

	const handleFilterTopicChange = evt => {
		const topic = evt.target.closest("th").id;
		let isAscending = false;

		if (topic === filterTopic) {
			isAscending = !selectedTopic;
		}

		let sortName = [...displayItems];

		if (topic !== "clientName") {
			sortName = [...displayItems].sort((a, b) => a.clientName.localeCompare(b.clientName));
		}

		const topicsSort = sortName.sort((a, b) => {
			if (a[topic] < b[topic]) return isAscending ? -1 : 1;
			if (a[topic] > b[topic]) return isAscending ? 1 : -1;
			return 0;
		});
		setDisplayItems(topicsSort);
		if (topic === filterTopic && selectedTopic === topic) {
			setSelectedTopic(isAscending ? topic : "");
		} else {
			setFilterTopic(topic);
			setSelectedTopic(isAscending ? topic : "");
		}
	};

	const handleDelete = id => {
		axios
			.delete(`/Testimonial/${id}`)
			.then(resp => {
				if (resp.status === 200) {
					const newItemList = items.filter(item => item.testimonialId !== id);
					setDisplayItems(newItemList);
					setDisplayModal(false);
					toast.success(`The testimonial by "${resp.data.clientName}" was deleted.`);
				}
			})
			.catch(err => {
				toast.error(err);
			});
	};

	const handleHideModalDisplay = () => {
		setDisplayModal(false);
	};

	const handleShowModal = (name, id) => {
		setDisplayModal(true);
		setDeleteItem({ title: name, id });
	};

	const itemsTable = displayItems.sort((a, b) => a.clientName > b.clientName ? 1 : -1).map(item => {
		return (
			<tr key={item.testimonialId}>
				<td>{item.clientName}</td>
				<td>{item.active ? 'Active' : ''}</td>
				<td>{item.testimonialText.substring(0, 50)}...</td>
				<td>
					<Link to={`/admin/testimonials/edit/${item.testimonialId}`}>
						<FontAwesomeIcon icon={faEdit} />
					</Link>
					<button
						onClick={() => handleShowModal(item.clientName, item.testimonialId)}
						aria-label="Delete Testimonial">
						<FontAwesomeIcon icon={faTrashCan} />
					</button>
				</td>
			</tr>
		);
	});

	const theadings = tableHeadings.map(heading => {
		let icon;
		if (heading.id === filterTopic) {
			icon =
				selectedTopic === heading.id ? (
					<FontAwesomeIcon icon={faArrowUpAZ} />
				) : (
					<FontAwesomeIcon icon={faArrowDownZA} />
				);
		}

		return (
			<th key={heading.id} id={heading.id} onClick={handleFilterTopicChange} className={heading.class}>
				{heading.topic} {icon}
			</th>
		);
	});

	let tableResults;

	if (isLoading) {
		tableResults = <LoadingIcon />;
	} else if (displayItems.length === 0 && searchTerm !== "") {
		tableResults = <p>Sorry, no results found. Please revise your search</p>;
	} else if (items.length === 0 && !searchTerm) {
		tableResults = <p>No testimonials found. Add a testimonial.</p>;
	} else {
		tableResults = (
			<table className={styles.articlesTable}>
				<thead>
					<tr>
						{theadings}
						<th>Options</th>
					</tr>
				</thead>
				<tbody>{itemsTable}</tbody>
			</table>
		);
	}

	return (
		<>
			{displayModal && <Backdrop show={displayModal} clicked={handleHideModalDisplay} />}
			{displayModal && <DeleteModal item={deleteItem} return={handleHideModalDisplay} delete={handleDelete} />}
			<h1>Testimonials</h1>
			<div className={styles.topRow}>
				<Link to="/admin/testimonials/add">
					<FontAwesomeIcon icon={faCirclePlus} /> Add a Testimonial
				</Link>
				<form>
					<input
						type="search"
						ref={inputRef}
						onChange={handleInputChange}
						placeholder="Search testimonials"></input>
					<button>
						<FontAwesomeIcon icon={faMagnifyingGlass} />
					</button>
				</form>
			</div>
			{tableResults}
		</>
	);
}
