import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styles from "./telehealth.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import LoadingIcon from "../../../components/loading";

function TeleHealth() {
	const [provider, setProvider] = useState(null);
	const [displayProviders, setDisplayProviders] = useState([]);
	const [providerList, setProviderList] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [revealList, setRevealList] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const inputRef = useRef(null);

	useEffect(() => {
		axios.get("/Provider").then(resp => {
			const sortFirstName = resp.data.sort((a, b) => a.firstName.localeCompare(b.firstName));
			const sortLastName = sortFirstName.sort((a, b) => a.lastName.localeCompare(b.lastName));

			setProviderList(sortLastName);
			setDisplayProviders(sortLastName);
			setIsLoading(false);
		});
	}, []);

	useEffect(() => {
		const timerId = setTimeout(() => {
			if (inputRef.current.value === searchTerm) {
				const filter = providerList.filter(
					elt =>
						searchTerm === "" ||
						searchTerm.includes(",") ||
						elt.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
						elt.lastName.toLowerCase().includes(searchTerm.toLowerCase())
				);
				setDisplayProviders(filter);
			}
		}, 500);

		return () => clearTimeout(timerId);
	}, [searchTerm, providerList]);

	const handleFocusDiv = () => {
		setRevealList(true);
	};

	const handleSelectIndividual = provider => {
		setProvider(provider);
		setRevealList(false);
		setSearchTerm(`${provider.lastName}, ${provider.firstName}`);
	};

	const handleSearchTerm = evt => {
		if (provider) {
			setProvider(null);
		}
		setSearchTerm(evt.target.value);
	};

	const handleChevronClick = (evt) => {
		evt.stopPropagation();
		setRevealList(val => !val);
	}

	let tele = (
		<div className={styles.providerList}>
			<ul>
				{displayProviders.map(provider => {
					return (
						<li id={provider.roomUrl} key={provider.roomUrl}>
							<button className={styles.provider} onClick={() => handleSelectIndividual(provider)}>
								{provider.lastName}, {provider.firstName}
							</button>
						</li>
					);
				})}
			</ul>
		</div>
	);

	if (displayProviders.length < 1 && !isLoading) {
		tele = (
			<div>
				<p>No providers found, please try again</p>
			</div>
		);
	}

	let callButton = (
		<button className={styles.disabledButton} disabled>
			Start Call
		</button>
	);
	if (provider) {
		callButton = (
			<Link className={styles.enabledButton} to={provider.toLink}>
				Start Call
			</Link>
		);
	}

	return (
		<section>
			<h1>TeleHealth</h1>
			<p className={styles.contentPara}>Start your TeleHealth session by selecting your care provider's name below.</p>
			<p className={styles.contentPara}>
				All appointments are scheduled in advance through our intake and access teams. Need help scheduling an
				appointment? Call <a href="tel:+18665342639">866-534-ANEW</a> or email us at <a href="mailto:helpnow@anewbh.com">helpnow@anewbh.com</a>.
			</p>
			<div className={styles.teleContainer}>
				<div className={styles.teleSearch} onClick={handleFocusDiv}>
					<input
						type="search"
						aria-label="Type provider's name or initial"
						placeholder="Type a name or initial"
						value={searchTerm}
						onChange={handleSearchTerm}
						ref={inputRef}></input>
					<button aria-label="Click to reveal list of providers"
						onClick={handleChevronClick}>
						<FontAwesomeIcon
							icon={revealList ? faChevronUp : faChevronDown}
							size="2xl"
							color="#033F63"></FontAwesomeIcon>
					</button>
				</div>
				<div className={styles.desktop}>{callButton}</div>
				{revealList && tele}
				{isLoading && <LoadingIcon />}
				<div className={styles.mobile}>{callButton}</div>
			</div>
		</section>
	);
}

export default TeleHealth;
