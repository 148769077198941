import { useState } from "react";
import styles from "./faq-item.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const FaqItem = props => {
	const [toggleContent, setToggleContent] = useState(false);

	const handleToggle = () => {
		setToggleContent(!toggleContent);
	};

	let iconSelect = toggleContent ? "open" : "close";

	let faqItem = toggleContent ? `${styles.faqItem} ${styles["open-content"]}` : `${styles.faqItem}`;

	return (
		<>
			<button className={styles.dropdownButton} onClick={handleToggle}>
				<div className={styles.dropdown}>
					{props.title}
					<FontAwesomeIcon icon={faChevronDown} className={iconSelect}></FontAwesomeIcon>
				</div>
			</button>

			{<div className={faqItem} dangerouslySetInnerHTML={{ __html: props.content }}></div>}
		</>
	);
};

export default FaqItem;
